export const en = {
  common: {
    abort: 'Cancel',
    by: 'by',
    and: 'and',
    datePicker: {
      weekdays: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    day: 'Day',
    dayN: 'Day {{dayNumber}}',
    days: 'days',
    month: 'month',
    month_plural: 'months',
    monthCount: '1 month',
    monthCount_plural: '{{count}} months',
    error: 'Error {{statusCode}}',
    remove: 'Erase',
    company: 'Company',
    department: 'Department',
    chart: {
      manager: {
        show: 'Show all managers',
        hide: 'Hide managers',
      },
      clock: {
        show: 'Display time',
      },
      absenceType: {
        toggleVAB: 'Show care of sick child (VAB)',
      },
    },
    gender: {
      men: '<0>{{percentage}}</0> men',
      women: '<0>{{percentage}}</0> women',
    },
    ongoing: 'Ongoing',
    list: {
      show: 'Show list',
      hide: 'Hide list',
      headers: {
        name: 'Name',
        causeOfAbsence: 'Cause of absence',
        startDate: 'From',
        estimatedReturn: 'Esti­mated time of return',
        department: 'Depart­ment',
        absenceDegree: 'Per­cent of absence',
        needForSupport: 'In need of support',
        isCurrent: 'Current',
        manager: 'Manager',
        date: 'Date',
        service: 'Service',
        category: 'Category',
        numberOfOccasions: 'Number of occasions',
        numberOfNotifications: 'Number of events',
        absenceType: 'Type of absence',
        workRelated: 'Work related',
      },
      showMore: 'Show more ({{remaining}})',
      readMore: 'Read more',
      minimize: 'Collapse',
      scrollToTop: 'Scroll to the top of the list',
      toggleShowAll: 'Show extended columns',
      loadingMore: 'Laddar fler rader',
      showSettings: 'Settings',
      showFilters: 'Show filters',
      toggleWorkrelated: 'Show work related sick leaves',
      toggleSickLeave: 'Show sick leave (excl. work related)',
      toggleSickLeave_noWorkRelated: 'Show sick leave',
    },
    occurrences: 'occasions',
    navigation: {
      report: 'Report',
      analysis: 'Analysis',
      rehabPlans: 'Rehab planner',
      numberOfEmployees: '{{count}} employees',
      employeesInCompany: 'of {{num}} on {{company}} that use Falck',
      today: 'Today',
      today_0: 'Today',
      today_plural: 'Today <1><2>{{count}}</2></1>',
      concern: 'Concern',
      generalStatisticsHeader: 'Sickness and Health Service Statistics',
    },
    period: {
      current: 'Current period',
      lastYear: 'Same period last year',
    },
    yes: 'Yes',
    no: 'No',
    multiple: 'Multiple',
    employeeWithActivePlan: 'Employees on sick leave with an active Rehab Plan',
    employeesOnSickleave: 'Employees on sick leave',
    relapse: 'Relapse',
    statusChange: 'New absence rate',
    previousEmployee: 'Previous employee',
    notificationTypes: {
      WORK_RELATED_ABSENCE: 'Work-related absence',
      LONG_TERM_ABSENCE: 'Long-term absence',
      LONG_TERM_ABSENCE_15_DAYS: 'Long-term absence longer than 14 days',
      LONG_TERM_ABSENCE_91_DAYS: 'Long-term absence longer than 90 days',
      LONG_TERM_ABSENCE_181_DAYS: 'Long-term absence longer than 180 days',
      REPEATED_SHORT_TERM_ABSENCES: 'Repeated short-term absence',
    },
    absenceTypeEnum: {
      SICK_LEAVE: 'Sick leave',
      CARE_OF_CHILD: 'Care of sick child (VAB)',
      UNKNOWN: 'Unknown',
    },
    masked: 'Anonymized',
    token: {
      expired:
        'Your session has expired and can no longer be renewed. Please log out and log back in again.',
      expiresSoon:
        'You have been inactive for a while. Do you want to stay logged in?',
      continue: 'Continue',
      logOut: 'Log out',
    },
  },
  AtRisk: {
    longTerm: {
      title: 'Long-term absence',
      person:
        '<0> {{ firstName }} </0> has been absent for more than {{numberOfDays}} days',
      byDays: 'Long-term absence in more than {{numberOfDays}} days',
      reported:
        ' {{ date }} {{firstName }} reported absence with work-related causes',
    },
    remove: {
      button: 'Erase risk mark',
      warning:
        'Are you sure you want to erase the risk mark from <1>{{firstName}} {{lastName}}</1>?',
      warningText:
        'Only erase the risk mark if you have been in contact with the employee, or if you already know that the employee left the risk zone.',
    },
    workRelated: {
      title: 'Work-related absence',
    },
    shortTerm: {
      title: 'Risk for long-term absence',
      person:
        '<0> {{ firstName }} </0> has been short-term absent {{numberOfAbsences}} occasions the last six months. This is a strong indication for future long-term absence.',
    },
  },
  CauseOfAbsence: {
    title: 'Absence divided by cause',
    title_days: 'Days of absence divided by cause',
  },
  ConditionsAndConditionCategories: {
    title: 'Absence divided by category',
    title_days: 'Days of absence divided by category',
    'Medicinska besvär': 'Medical disorders',
    'Besvär i rörelseapparaten': 'Locomotor disorders',
    'Psykosociala besvär': 'Psycho­social disorders',
    VAB: 'Care of a sick child (VAB)',
    'Okänd kategori': 'Unknown category',
    Allergibesvär: 'Allergies',
    'Allmänna sjukdomsbesvär': 'General sickness symptoms',
    'Besvär arm/hand': 'Arm/​hand symptoms',
    'Besvär ben/fot': 'Leg/​foot symptoms',
    'Besvär bröstkorg/revben': 'Chest/​ribs symptoms',
    'Besvär ländrygg/höft': 'Low back/​hip symptoms',
    'Besvär nacke/bröstrygg/axlar': 'Neck/​chest/​shoulder symptoms',
    Förkylningsbesvär: 'Cold',
    'Graviditetsrelaterade besvär': 'Pregnancy-​related symptoms',
    'Hjärt-/kärlbesvär': 'Heart and circulatory system related symptoms',
    Hudbesvär: 'Skin disorders',
    'Huvudvärk/yrsel': 'Headache/​dizziness',
    'Hörsel-/öronbesvär': 'Hearing/​ear symptoms',
    Influensabesvär: 'Influenza',
    'Lung-/luftvägsbesvär': 'Lung/​respiratory distress',
    'Mag-/tarmbesvär': 'Stomach/​intestinal symptoms',
    Magsjukebesvär: 'Stomach flu',
    'Operation/konvalescens': 'Surgery/​convalescence',
    Sekretess: 'Secrecy',
    'Sjukvårdande behandling': 'Healthcare treatment',
    'Stressrelaterade besvär': 'Stress-​related disorders',
    'Syn-/ögonbesvär': 'Vision problems/​eye symptoms',
    'Vill ej ange orsak': 'Do not want to specify cause',
    'Övertaget ärende': 'Transmitted sickness case',
    'Orsak ej förmedlad': 'Cause not reported',
    'Okänd anledning': 'Unknown cause',
  },
  ConditionCategoryIds: {
    'Okänd kategori': 'Unknown cate­gory',
    0: 'Secrecy',
    2: '',
    3: 'Loco­motor dis­orders',
    4: 'Medical dis­orders',
    5: 'Psycho­social dis­orders',
    6: 'Care of a sick child (VAB)',
    7: 'Cause not reported',
  },
  ConditionIds: {
    'Okänd anledning': 'Unknown condition',
    0: 'Secrecy',
    2: '',
    3: 'Allergies',
    4: 'General sickness symptoms',
    5: 'Arm/​hand symptoms',
    6: 'Leg/​foot symptoms',
    7: 'Chest/​ribs symptoms',
    8: 'Low back/​hip symptoms',
    9: 'Neck/​chest/​shoulder symptoms',
    10: 'Cold',
    11: 'Pregnancy-​related symptoms',
    12: 'Heart and circulatory system related symptoms',
    13: 'Skin disorders',
    14: 'Headache/​dizziness',
    15: 'Hearing/​ear symptoms',
    16: 'Influenza',
    17: 'Lung/​respiratory distress',
    18: 'Stomach/​intestinal symptoms',
    19: 'Stomach flu',
    20: 'Munhåla/tänder',
    21: 'Surgery/​convalescence',
    22: 'Psycho­social dis­orders',
    23: 'Healthcare treatment',
    24: 'Stress-​related disorders',
    25: 'Vision problems/​eye symptoms',
    26: 'Care of a sick child (VAB)',
    27: 'Do not want to specify cause',
    28: 'Allergies Allergi/­över­känslighet',
    29: 'Behandling/­läkar­besök',
    30: 'Biv. Vaccin',
    31: 'Extre­miteter övre/­nedre',
    32: 'Fever',
    33: 'Pregnancy-​related symptoms',
    34: 'Heart and circulatory system related symptoms',
    35: 'Hud',
    36: 'Huvud/­Neuro',
    37: 'Konfi­dentiellt',
    38: 'Luft­vägar/­lungor exkl. ÖLI',
    39: 'Lung distress',
    40: 'Mage/­tarm',
    41: 'Nacke/­thorax/­rygg',
    42: 'Operation',
    43: 'Psyk',
    44: 'Rörelse­apparat belastnings­orsak',
    45: 'Stress',
    46: 'Trötthet',
    47: 'Urin­vägar/­underlivs­besvär',
    48: 'Vård av anhörig',
    49: 'Ögon',
    50: 'Öron',
    51: 'Övre luft­vägs­infektion',
    52: 'Övrigt',
    53: 'öö Sär­skild Diagnos­grupp',
    54: 'Transmitted sickness case',
  },
  ConditionsLegend: {
    sickness: 'Sickness',
    vab: 'Care of children (VAB)',
    upcoming: 'Upcoming',
  },
  Counseling: {
    pane: {
      current: {
        heading: 'Current mood',
        list: [
          'How do you feel today – both physically and mentally?',
          'Is there any specifik causes to your illness/absence?',
          'Have you felt extra tired or stressed recently? How do you handle it?',
          'How do you handle when it becomes stressful?',
        ],
      },
      plan: {
        heading: 'Action plan',
        list: [
          'What can you as a manager do to improve health?',
          'What can you as a employee do to strengthen your health and improve your work capacity?',
          'When is the next time for a follow up meeting?',
        ],
      },
      private: {
        heading: 'Private',
        list: [
          'How does the working time suit your social situation?',
          'Is there any connection between your cause of condition and private life?',
          'How is your family situation? Social support and economy?',
          'What is your relation to physical activity, diet, sleep, tobacco, alcohol?',
          'What kind of hobbies do you have in your spare time and how much time do you spend on them?',
        ],
      },
      work: {
        heading: 'The work',
        list: [
          'How do you thrive at work – both mentally and socially?',
          'Are there any connections between your cause of condition and workplace?',
          'Do you consider that the right conditions exists for the work; physically, mentally and socially?',
          'Is it possible to get support and encouragement from collegues and managers when needed?',
          'Can you feel an uneasiness going to work?',
        ],
      },
    },
    print: 'Print',
    title: 'Counseling support',
    text: {
      intro:
        'Use the checklist as a support for a dialogue about the health and workplace. The aim of the dialogue is to examine how you as an employee feel, and how you as a manager can support and prevent sickness.',
      second: 'Feel free to <1>print</1> the questions if you like.',
    },
  },
  DepartmentPicker: {
    label: 'Department:',
    filter: 'Search departments',
    myDepartments: 'My departments',
    allDepartments: 'All departments',
    noSearchResult: 'Sorry, no results for "{{searchValue}}"',
    viewSelector: {
      aggregated: {
        buttonText: 'General statistics',
        toolTipText:
          'Aggregate statistics for departments to which you are authorized',
      },
    },
    lockInformation: {
      lacksAuthorization: 'You do not have permission for this department.',
      lacksContract: 'You do not have an active contract for this department.',
      generalStatisticsInformation:
        'The department is excluded in General Statistics.',
    },
    help: {
      header: 'How it works',
      intro:
        "Click the department's name to see underlying departments. If there is no checkbox you do not have access to see statistics for that department.",
      clickSteps: {
        one: 'Click <1>one</1> time to select:',
        oneTitle: 'Department inc. underlying',
        two: 'Click <1>two</1> times to select:',
        twoTitle: 'Department excl. underlying',
        three: 'Click <1>three</1> times to deselect:',
        threeTitle: 'Non-selected department',
      },
      parenthesesDescription:
        'Parentheses shows the number of underlying departments that are selected',
      shortcuts: {
        header: 'Shortcuts',
        clickText: 'click',
        ctrlPlusClick: 'toggle single node',
        altPlusClick:
          'deselect node inc. underlying departments regardless of their current state',
      },
    },
    footer: {
      helpActionText: 'How it works',
      deselectAllActionText: 'Deselect all',
      selectedDepartmentsActionText: '<0>{{count}}</0> selected department',
      selectedDepartmentsActionText_plural:
        '<0>{{count}}</0> selected departments',
      showSelection: 'Show selection',
    },
    selectedDepartments: {
      header: 'Selected departments',
      deselectAllActionText: 'Deselect all',
      cancelActionText: 'Cancel',
      saveActionText: 'Save',
    },
    search: {
      input: {
        placeholder: 'Search for department',
      },
    },
    columnMenu: {
      select: {
        header: 'Select',
        actions: {
          selectIncludingUnderlying: 'All inc. underlying',
          selectExcludingUnderlying: 'All excl. underlying',
          selectOnlyUnderlying: 'Only underlying',
        },
      },
      deselect: {
        header: 'Deselect',
        actions: {
          deselectIncludingUnderlying: 'All inc. underlying',
          deselectExcludingUnderlying: 'All excl. underlying',
          deselectOnlyUnderlying: 'Only underlying',
        },
      },
    },
  },
  DepartmentPickerToggle: {
    label: 'Departments',
    labelDepartment: 'Department',
    chosenDepartments: 'Showing statistics for <1>{{count}}</1>',
  },
  ErrorPage: {
    server: {
      headline: 'Sorry, an error occured.',
      first:
        'Something went wrong, but we are working as fast as we can to solve it!',
      second:
        'Please try again later or contact <1>sjukochfrisk@falcksverige.se</1> for further information.',
    },
    forbidden: {
      headline: 'Permission Denied',
      first: 'You are not authorized to view this page.',
    },
    notFound: {
      headline: 'Sorry, the page you are looking for does not longer exist.',
      first:
        'Turn back to <1>start</1> or contact <3>sjukochfrisk@falcksverige.se</3> for further information.',
    },
    unavailable: {
      headline:
        'Improvements of the site is now being implemented. Please try again later!',
      first:
        'The service is not available right now, please try again later. Regular improvements is implemented between <4>06.00–06.20</4> AM.',
      second:
        'Please contact <1>sjukochfrisk@falcksverige.se</1> for further information.',
    },
  },
  HrAnalysis: {
    comparisonTitle: 'for customers to Falck',
    comparisonEntity: 'all Sickness and Health Service customers',
  },
  HrToday: {
    absentEmployees: 'absent employees',
    workRelated: '<2>{{ count }}</2> of them with work-related absence.',
    workersPerPeriod: 'Employees per sick leave period',
    sickLeave: '<0>{{count}}</0> employees absent',
    sickLeaveNonSelected: 'Absent employees',
    partialSupportForRehabplan:
      'The selection contains departments whose companies do not use the rehab planner',
    returningToday: 'employees returning today',
    absentEmployeesToday_0: 'No one is absent today',
    absentEmployeesToday: '<0>{{count}}</0> new absent employee today',
    absentEmployeesToday_plural: '<0>{{count}}</0> new absent employees today',
    returningTodayLoading: 'Fetching employees returning today',
    absenceHistoryLoading: 'Fetching absence history',
    employeesPerInterval: {
      loading: 'Retrieving employees per sick leave period',
    },
  },
  Notifications: {
    item: {
      title: 'Empolyee',
      seeMore: 'More details and absence history.',
      name: '<0>{{personName}}</0> <1>({{notificationCount}} events)</1>',
      name_0: '<0>{{personName}}</0>',
    },
    longTerm: {
      label:
        'Employees with long-term absence more than {{numberOfDays}} days.',
    },
    repeated: {
      label: 'Employees with repeated short-term absence.',
      intro:
        'Repeated short-term absence is when a person has been absent for 3 or more times, during 6 months. It can be an indication of eventual forthcoming long-term absence.',
      second:
        'The absence often has natural causes, but our recommendation is to follow up all repeated short-term absence to prevent long-term absence.',
    },
    title_plural: '<0>{{count}}</0> events to act on',
    title: '<0>{{count}}</0> event to act on',
    workRelated: {
      label: 'Employees that reported work-related absence',
    },
    loading: 'Fetching notifications',
  },
  Notification: {
    longTerm: 'Long-term absence longer than {{numberOfDays}} days ({{count}})',
    workRelated: 'Work-related absence {{count}}',
    shortTerm: 'Repeated short-term absence {{count}}',
  },
  Person: {
    absence: {
      title: 'Occasions of absence per month.',
    },
    absent: 'Absent',
    healthy: 'Healthy',
    employed: 'Employed at {{- department}}',
    lastMonths: 'Last {{count}} months',
    numberOfAbsences: '<0>{{count}}</0> occasion with',
    numberOfAbsences_plural: '<0>{{count}}</0> occasions with',
    numberOfAbsenceDays: '<0></0> day of absence',
    numberOfAbsenceDays_plural: '<0></0> days of absence',
    absenceType: 'Absence type',
  },
  PersonAbsenceHistoryTable: {
    periodDates: '{{fromDate}} - {{toDate}}',
    history: 'Absence history',
    table: {
      date: 'Date',
      numberOfDays: 'Number of days',
      condition: 'Cause of condition',
      percent: 'Percent of absence',
      relapsePercent: 'Relapse/Updated percent of absence',
      workRelated: 'Work-related',
      conditionType: 'Type of absence',
    },
    showAll: 'Show all absence history',
  },
  PersonServiceUseHistoryTable: {
    activity: 'Activity',
    showAll: 'Show all activity',
  },
  PlannedReturns: {
    heading: 'Planned returns the upcoming 7 days',
    date: '{{date}}',
    date_today: 'Today, {{date}}',
  },
  Report: {
    report: 'Report',
    headline: 'Create a report',
    export: 'Export',
    period: 'Period',
    selectAllColumns: 'Select all',
    summaryHeading: 'Case list',
    summation: 'Summary',
    absencesForPeriod: 'Absent occasions per period',
    absenceDaysTotal: 'Total number of absent days',
    absenceDaysForPeriod: 'Absent days per period',
    sicknessAbsence: 'Sick leave',
    vabAbsence: 'Care of children (VAB)',
    abstractAbsences: 'Occasions of absence (not including relapse)',
    noAbsencesForPeriod: 'There is no absence within the selected time period.',
    noSelectedColumns: 'No columns selected',
    showAll: 'Show all',
    exportFileNameHr: 'General statistics',
    columns: {
      employmentNumber: 'Employee number',
      name: 'Name',
      department: 'Unit',
      parentDepartment: 'Parent unit',
      conditionName: 'Cause of absence',
      conditionType: 'Type of absence',
      absencesForPeriod: 'Absent occasions per period',
      absencesExclRelapsesForPeriod:
        'Occasion of absence (not including relapse)',
      fromDate: 'The sick leave notification is valid from',
      toDate: 'The last absence day',
      realReturnDate: 'Expected return',
      callDatetime: 'The absence report was submitted',
      callDatetimeWell: 'The recovery report was submitted',
      isAbsent: 'Recovered',
      absenceStatus: 'Absence rate',
      absenceDaysForPeriod: 'Absent days per period',
      totalAbsenceDaysForPeriod: 'Total number of absent days',
      workRelated: 'Work-related absence',
    },
  },
  RehabPlanList: {
    header: '<0>{{count}}</0> Rehab activities',
    header_ongoing: '<0>{{count}}</0> Ongoing rehab activity',
    header_ongoing_plural: '<0>{{count}}</0> Ongoing rehab activities',
    header_concluded: '<0>{{count}}</0> Concluded rehab activity',
    header_concluded_plural: '<0>{{count}}</0> Concluded rehab activities',
    header_plannedMeasures:
      '<0>{{count}}</0> Rehab activity with a planned action',
    header_plannedMeasures_plural:
      '<0>{{count}}</0> Rehab activities with a planned action',
    loading: {
      stats: 'Loading information about rehab activities',
      initial: 'Loading the list of rehab activities',
    },
    error: {
      stats:
        'An error occurred while fetching information about rehab activities',
      initial: 'An error occurred while fetching the list of rehab activities',
      more: 'An error occurred while fetching the next part of the list',
    },
    export: {
      filename: 'rehab activities {{ date, iso-date }}',
      sheetName: 'Rehab activities',
    },
    columns: {
      employee: 'Employee',
      rehabStatus: 'Rehab status',
      plannedMeasures: 'Planned measure',
      department: 'Department',
      latestNote: 'Latest note',
    },
    rehabStatus: {
      ongoing: 'Ongoing since {{ date, iso-date }}',
      ongoing_datetime: 'Ongoing since {{ date, iso-date-time }}',
      concluded: 'Concluded at {{ date, iso-date }}',
      concluded_datetime: 'Concluded at {{ date, iso-date-time }}',
    },
    filters: {
      ongoing: 'Ongoing',
      concluded: 'Concluded',
      plannedMeasures: 'Planned measure',
    },
    latestNote: {
      planForReturnToWork:
        '{{date, iso-date }}: $t(RehabPlanManager.constants.planForReturnToWork)',
      planForReturnToWork_datetime:
        '{{date, iso-date-time }}:  $t(RehabPlanManager.constants.planForReturnToWork)',
      note: '{{date, iso-date }}:  $t(RehabPlanManager.constants.notes)',
      note_datetime:
        '{{date, iso-date-time }}:  $t(RehabPlanManager.constants.notes)',
      wellnessCheck:
        '{{date, iso-date }}:  $t(RehabPlanManager.constants.wellnessCheck)',
      wellnessCheck_datetime:
        '{{date, iso-date-time }}: $t(RehabPlanManager.constants.wellnessCheck)',
      file: '{{date, iso-date }}: File upload',
      file_datetime: '{{date, iso-date-time }}: File upload',
    },
    plannedMeasures: {
      planForReturnToWork: '{{ date, iso-date }}: Next meeting',
      planForReturnToWork_datetime: '{{ date, iso-date-time }}: Next meeting',
      wellnessCheck: '{{ date, iso-date }}: Follow up',
      wellnessCheck_datetime: '{{ date, iso-date-time }}: Follow up',
    },
  },
  RehabPlanManager: {
    title: 'Rehab Planner',
    constants: {
      wellnessCheck: 'Wellness Check',
      planForReturnToWork: 'Plan for returning to work',
      notes: 'Note',
    },
    employment: 'Employed at {{name}}',
    print: 'Print',
    personNumber: 'Social Security Number',
    save: 'Save',
    close: 'Close',
    todaysDate: 'Todays date',
    date: 'Date',
    employer: 'Employer',
    employee: 'Empolyee',
    thirdParty: 'Third party',
    introduction: [
      'With the Rehab Planner you and your employee can create a plan for how to improve the wellness and get back to work as smooth as possible.',
      'Create a Rehab Plan and fill in the Wellness Check together. If needed, fill in a Plan for returning to work and send it to Försäkringskassan.',
      'The employee has the rights to take part of the documentation in the Rehab Plan by anytime.',
    ],
    ongoing: 'Ongoing',
    finishedPlans: 'Finished',
    noPlans: 'There are no ongoing plans for the employee.',
    startPlan: 'Create a new Rehab Plan',
    startWellnessCheck: 'Start with a Wellness Check',
    startPlanToReturnToWork: 'Create a Plan for returning to work',
    startNote: 'Start with a note',
    startFile: 'Start with uploading a file',
    noArchivedPlans: 'There are no finished Rehab Plans for the employee.',
    noAccessNoActivePlans: [
      'The employee has no active rehab plans',
      'To see the content of the rehab plan, you need the role <em>HR</em>, <em>Rehab Coordinator</em> or <em>Manager</em>.',
    ],
    fileUploadErrors: {
      tooLarge: 'The file is to large. The largest allowed file size is 20 MB', // TODO: use value from config
      uploadError:
        'Something went wrong when uploading the file. Please try again later.',
      unknown: 'An unknown error occurred. Please try again later.',
    },
  },
  RehabPlanManagerPlan: {
    title: 'Rehab Plan',
    ongoing: 'started at {{date}}',
    archived: 'finished at {{date}}',
    archive: 'Finish the Plan',
    addWellnessCheck: 'New wellness check',
    addplanForReturnToWork: 'New plan for returning to work',
    addNote: 'New note',
    addFile: 'Upload document',
    resumePlan: 'Resume Plan',
    removePlan: 'Erase Plan',

    removeModal: {
      title: 'Are you sure you want to erase the Plan?',
      text: 'The Plan and added documents will be erased and you will no longer be able to recreate them.',
    },
    noAccessActivePlan: [
      'The employee has no active rehab plans',
      'To see the content of the rehab plan, you need the role <em>HR</em>, <em>Rehab Coordinator</em> or <em>Manager</em>.',
    ],
  },
  RehabPlanManagerDocument: {
    updatedBy_unknown: 'Updated by Unknown user at {{timestamp}}',
    updatedBy: 'Updated by {{author}} at {{timestamp}}',
    createdBy_unknown: 'Created by Unknown user at {{timestamp}}',
    createdBy: 'Created by {{author}} at {{timestamp}}',
    modal: {
      title: 'Are you sure you want to erase the document?',
      text: 'The document will be erased and you will no longer be able to recreate them.',
    },
  },
  RiskTypes: {
    headline: 'Contact Falck for Counseling & Support',
    counselingStart: 'Start a Counseling support meeting ',
    checklist: [
      'Maintain regular meetings with your employee. Make sure the existing plan works, otherwise it needs to be reconsidered to turn valuable.',
      'Your employee is obliged to participate in the planning, to achieve going back to work as soon as possible.',
      'Set up a meeting with a doctor if needed.',
      'Compensate the work as much as possible. Ex. tasks, work time, help tools.',
      'Consider if a part time sick leave is suitable to shorten the length of the sick leave.',
      'Consider job training if the part time sick leave is not working out. It is undemanding and can be approved by Försäkringskassan if they consider it an effective action for rehabilitation.',
      'Participate in follow-up meetings, if Försäkringskassan approves.',
      'Falck can contribute with our gathered expertise to assure an effective process of rehabilitation.',
    ],
    intro: 'As a manager you can:',
    invite: 'Invite to dialogue',
    workRelated: {
      first:
        'As an employer you have responsibility of the work environment, so that the employees can accomplish their tasks without being hurt or ill.',
      second: {
        headline: 'Start with the Systematic work environment management.',
        list: {
          investigate: 'Examine',
          riskAnalysis: 'Do a risk analysis',
          act: 'Take action',
          followUp: 'Follow up',
        },
        print: 'Print and fill in together with your employee.',
        pdf: 'Dialogue checklist (PDF)',
      },
    },
    longTerm: {
      headline: 'From day 15',
      insurance:
        'Your employee is now getting sick pay from Försäkringskassan.',
      rehab:
        'The work ability of the employee is now being examined, according to the rehab process of Försäkringskassan. You will get a judgement if the employee is able to do the ordinary work, or if you as an employer should offer temporary work tasks instead.',
      rehabPlaneraren: {
        texts: [
          'If you have not created a Rehab Plan for the absence before day 15, start to fill in the Wellness Check.',
          'Continue from day 15, Plan for returning to work.',
          'Bear in mind to inform your employee about the rights to bring a Safety Agent/Representative from the Union, to the meetings.',
          'The Plan for returning to work should be filled in before day 30, if you estimate that your employee will be sick for at least 60 days.',
        ],
        insurance: 'Read the information from Försäkringskassan',
      },
    },
    longerTerm: {
      headline: 'From day 91',
      expertice:
        'Falck can contribute with our gathered expertise to assure an effective process of rehabilitation.',
      insurance: 'Read the information from Försäkringskassan',
    },
    longestTerm: {
      headline: 'From day 181-365',
      texts: [
        'Your employee now only have the right to get sick pay in the extent that he/she is not able to accomplish any duties on the ordinary labour market.',
        'Before day 180 Försäkringskassan examines if the employee is able to accomplish work outside your business. If so, the right of sick pay can expire.',
      ],
      exceptions: {
        headline: 'Exceptions:',
        texts: [
          'According to the assessment of Försäkringskassan the employee will be able to get back to work in the usual extent, in some part of your business, before day 366. The medical attestation form must be filled in with both diagnosis and prognosis, but also a concrete plan to support the returning to work.',
          'It is unfair to do an examination of the ordinary labour market if your employee has a severe disease. In that case it is better to consider the ability in relation to work duties.',
          'Your employee should still continue to participate in the planning and actions, with the aim to get back to work as soon as possible.',
        ],
      },
      responsibility:
        'The expectations of you as a manager is to continue with the active rehabilitation work.',
    },
    repeated: {
      schedule:
        'Speak directly with the employee and choose a time for you to meet.',
      counseling:
        'Share the <1>counseling support</1> with the employee in forehand, so that the employee can be prepared of what to come.',
      talk: 'Start a dialouge',
      purpose:
        'Make sure to tell about the purpose with the dialogue (for example repeated short-term absence). Clarify that as a manager it is your responsibility to prevent illness at work. Explain why you are worried. Try to create an open dialogue that does not force the employee to defend him- or herself.',
      help: 'Get advices from the <1>Counseling Support</1>.',
      createPlan: 'Create a Rehab Plan and follow up',
      endMeeting:
        'If needed, create a Rehab Plan that tells; who is responsible, what the responsibility is about and when it is time to follow up. End the meeting with the next step and create a booking for the follow-up meeting.',
      reminder: 'Have in mind',
      indication:
        'A person with repeated short-term absence can be an indication of coming long-term absence – but have in mind that it can be any other reasons for the absence.',
      askForHelp: 'Ask the HR-department for help if you are feeling insecure.',
      offerSupport:
        'Tell the employee about the possibilites to bring a representative of the Union or any other supportive person.',
    },
  },
  SearchEmployees: {
    placeholder: 'Search for employees',
    noResult: "Sorry, we could not find <1>'{{value}}'</1>.",
  },
  SiteFooter: {
    about: 'About Falck Statistics',
  },
  SiteHeader: {
    logout: 'Log out',
    home: 'Go to the customer page',
    userGuide: 'User guide',
  },
  StatisticsAnalysis: {
    series: [
      {
        label: 'Total sick leave',
        tooltip: 'total sick leave',
      },
      {
        label: 'Care of children (VAB)',
      },
      {
        label: 'Sick leave last year',
        tooltip: 'sick leave last year',
      },
      {
        label: 'Long-term sick leave',
        tooltip: 'long-term sick leave',
      },
      {
        label: 'Short-term sick leave',
        tooltip: 'short-term sick leave',
      },
    ],
    decimalsAreRounded: 'Decimals are rounded',
    lastMonths:
      'The statistics are based on {{periodText}} with a comparison of {{entity}}.',
    attendance: 'presence',
    absenceFree: 'Employees without sick leave',
    shortTerm: 'Short-term absence',
    longTerm: 'Long-term absence',
    totalAbsence: 'Absence in total',
    daysPerEmployee: 'Sick days per employee',
    daysPerShortTerm: 'Sick days per short-term absence',
    absencesPerEmployee: 'sick days per employee',
    trend: 'Trend',
    absenceOccasions: 'occasions with',
    absentDays: 'absent days',
    absenceLastMonths: 'Absence {{periodText}}',
    notificationsWhere: 'events',
    areCurrent: 'current',
    noNotifications: 'No events',
    noServiceUses: 'No activities',
    serviceUses: 'activity',
    serviceUses_plural: 'activities',
    calculating: 'Calculating statistics',
    analysis: 'Analysis',
    periodRangeTitle_current: 'Displaying <1>current month</1>',
    periodRangeTitle_current_plural:
      'Displaying <1>{{periodStart, day-month-year}} – today ($t(common.monthCount, {"count": {{months}}}))</1>',
    periodRangeTitle_notcurrent:
      'Displaying <1>{{periodStart, month-year}}</1>',
    periodRangeTitle_notcurrent_plural:
      'Displaying <1>{{periodStart, month-year}} – {{periodEnd, month-year}} ($t(common.monthCount))</1>',
    periodRangeSubTitle:
      'The statistics are based on $t(common.monthCount, {"count": 1}) with a comparison of {{comparisonEntity}}',
    periodRangeSubTitle_plural:
      'The statistics are based on $t(common.monthCount) with a comparison of {{comparisonEntity}}',
    periodRangeSubTitle_current:
      'The statistics are based on the current month with a comparison of {{comparisonEntity}}',
    periodRangeSubTitle_current_plural:
      'The statistics are based on the current month and $t(common.monthCount, {"count": {{months}}}) back in time with a comparison of {{comparisonEntity}}',
    DataAnalysis: {
      title: 'Key figures',
      periodPickerShowing: 'Change period',
      employeeCount: 'employees',
      employeeCountHelpText:
        'Number of employees for the selected department(s) without consideration for employment rate for the selected period.',
      workingDaysCount: 'employment days',
      workingDaysCountHelpText:
        'Number of employment days with consideration for employment rate for the selected period.',
      attendance: 'presence',
      attendanceHelpText_company:
        'Number of employees who have not been absent due to sickness to themselves. Compared to the total number of employees for the selected department(s) and period.\n\nCompared to the entire company',
      attendanceHelpText_previa:
        'Number of employees who have not been absent due to sickness to themselves. Compared to the total number of employees for the selected department(s) and period.\n\nCompared to all of Falck',
      shortTermAbsenceTitle: 'Short-term absence',
      longTermAbsenceTitle: 'Long-term absence',
      sickDaysPerEmployee: 'Sick days per employee',
      sickOccasionsPerEmployee: 'Absences per employee',
      sickDaysPerShortTermAbsence: 'Sick days per short-term absence',
      absenceSummaryTitle: '<0><0></0></0> absence in total',
      otherSummaryTitle:
        '<0><0></0></0> employees with work related absence<br/><3><0></0></3> employees with repeated short-term absence',
    },
    AbsenceSummary: {
      title:
        '<0><0></0></0> occasions of absence with<br/><3><0></0></3> days of absence',
      sickLeaveDays: 'Days of sick leave',
      sickLeaveCount: 'Occasions of sick leave',
      careOfChildDays: 'Days of caring for a sick child',
      careOfChildCount: 'Occasions of care for a sick child',
    },
    Trend: {
      title: 'Trend',
      helpText: `Absence in percentage
The ratio of absence days divided by the sum of all possible days for the selected period and departments. The calculation is based on calendar days and takes the absence rate into account.

Occasions of absence
The number of absences that was ongoing during the selected period.

Absence days
The sum of all days of absence during the selected period. The calculation takes the absence rate into account.`,
      AbsencePercentage: 'Absence in percentage',
      AbsenceCount: 'Occasions of absence',
      AbsenceDays: 'Absence days',
      exportButtonTitle: 'Export to Excel',
      absencePercentage: {
        series: {
          allSickLeave: {
            label: 'Total sick leave',
            tooltip: 'total sick leave',
          },
          shortTermAbsence: {
            label: 'Short-term absence',
            tooltip: 'short-term absence',
          },
          longTermAbsence: {
            label: 'Long-term absence',
            tooltip: 'long-term absence',
          },
          vab: {
            label: 'Care of children (VAB)',
            tooltip: 'care of children (VAB)',
          },
          totalSickLeaveLastYear: {
            label: 'Sick leave last year',
            tooltip: 'sick leave last year',
          },
        },
      },
      absenceCount: {
        series: {
          allSickLeave: {
            label: 'Occasions of sick leave',
            tooltip: 'occasions of sick leave',
          },
          shortTermAbsence: {
            label: 'Occasions of short-term absence',
            tooltip: 'occasions of short-term absence',
          },
          longTermAbsence: {
            label: 'Occasions of long-term absence',
            tooltip: 'occasions of long-term absence',
          },
          vab: {
            label: 'Care of children (VAB) occasions',
            tooltip: 'care of children (VAB) occasions',
          },
          totalSickLeaveLastYear: {
            label: 'Occasions of sick leave last year',
            tooltip: 'occasions of sick leave last year',
          },
        },
      },
      absenceDays: {
        series: {
          allSickLeave: {
            label: 'Sick leave days',
            tooltip: 'sick leave days',
          },
          shortTermAbsence: {
            label: 'Short-term absence days',
            tooltip: 'short-term absence days',
          },
          longTermAbsence: {
            label: 'Long-term absence days',
            tooltip: 'long-term absence days',
          },
          vab: {
            label: 'Care of children (VAB) days',
            tooltip: 'care of children (VAB) days',
          },
          totalSickLeaveLastYear: {
            label: 'Sick leave days last year',
            tooltip: 'sick leave days last year',
          },
        },
      },
      Excel: {
        worksheetName: 'Trend',
        title_percentage: 'Trend – Absence',
        title_count: 'Trend – Occasions of absence',
        title_days: 'Trend – Absence days',
        periodText:
          'Period: {{ startDate, iso-date }} – {{ endDate, iso-date }}',
        fileName_percentage:
          'Trend - Absence {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        fileName_count:
          'Trend - Occasions of absence {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        fileName_days:
          'Trend - Absence days {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        headers: {
          year: 'Year',
          month: 'Month',
          totalSickLeave: 'Total sick leave',
          longTermAbsence: 'Long-term absence',
          shortTermAbsence: 'Short-term absence',
          vab: 'Care of child (VAB)',
          totalSickLeaveLastYear: 'Sick leave last year',
        },
      },
    },
    CategoriesAndConditions: {
      title: 'Cause category and cause',
      AbsenceCount: 'Occasions of absence',
      AbsenceDays: 'Absence days',
      exportButtonTitle: 'Export to Excel',
      Excel: {
        worksheetName: 'Category and cause',
        title_count: 'Cause category and cause',
        title_days: 'Cause category and cause',
        periodText:
          'Period: {{ startDate, iso-date }} – {{ endDate, iso-date }}',
        fileName_count:
          'Category and cause - Occasions of absence {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        fileName_days:
          'Category and cause - Absence days {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        headers: {
          conditionCategory: 'Category',
          condition: 'Cause',
          count_count: 'Occasions of absence divided by cause',
          count_days: 'Absence days divided by cause',
        },
      },
    },
    AbsenceEvents: {
      title: 'Repeated short-term absence',
      exportButtonTitle: 'Export to Excel',
    },
    ExportKeyValues: {
      action: 'Statistics export',
      Excel: {
        title: 'Period {{periodStart, month-year}}',
        title_plural:
          'Period {{periodStart, month-year}} – {{periodEnd, month-year}} ($t(common.monthCount))',
        worksheetName: 'Statistics',
        fileName:
          'Statistics export {{ startDate, iso-date }} – {{ endDate, iso-date }}',
        headers: {
          keyFigures: 'Key figures',
          employeeCount: 'Employees',
          totalEmploymentDays: 'Employment days',
          employeeAttendancePercentage: 'Presence',
          totalSickLeave_withFilter: 'Absence in total',
          totalSickLeave_loading: 'Absence in total',
          totalSickLeave_company:
            'Absence in total (Comparison the whole company)',
          totalSickLeave_previa:
            "Absence in total (Comparison Falck's customers)",
          shortTermAbsences_withFilter: 'Short-term absence',
          shortTermAbsences_loading: 'Short-term absence',
          shortTermAbsences_company:
            'Short-term absence (Comparison the whole company)',
          shortTermAbsences_previa:
            "Short-term absence (Comparison Falck's customers)",
          longTermAbsences_withFilter: 'Long-term absence',
          longTermAbsences_loading: 'Long-term absence',
          longTermAbsences_company:
            'Long-term absence (Comparison the whole company)',
          longTermAbsences_previa:
            "Long-term absence (Comparison Falck's customers)",
          personWithWorkRelatedAbsenceCount:
            'Employees with work related absence',
          personWithRepeatedShortTermAbsenceCount:
            'Employees with repeated short-term absence',
          absentDaysPerEmployment: 'Sick days per employee',
          absencesPerEmployee: 'Absences per employee',
          absentDaysPerAbsences: 'Sick days per short-term absence',
          totalAbsences: 'Occasions of absence',
          totalAbsentDays: 'Days of absence',
          totalSicknessAbsentDays: 'Days of sick leave',
          totalSicknessAbsences: 'Occasions of sick leave',
          totalCareOfChildAbsentDays: 'Days of caring for a sick child',
          totalCareOfChildAbsences: 'Occasions of care for a sick child',
        },
      },
    },
    AnalysisFilter: {
      Male: 'Male',
      Female: 'Female',
      ActionLabel: '{{minAge}} – {{maxAge}} age',
      SubmitButton: 'OK',
      ResetButton: 'Reset filter',
      GenderPicker: 'Gender',
      EmploymentTypePicker: 'Employment type',
      AgePicker: 'Age',
      Heading: 'Select filter settings',
      HoverText: 'Select filter settings',
      MinAge: 'Select minimum age',
      MaxAge: 'Select maximum age',
      ChooseEmploymentType: 'Choose employment type',
      CheckAllEmploymentTypes: 'Check all',
      UncheckAllEmploymentTypes: 'Uncheck all',
      InvertEmploymentTypesSelection: 'Invert selection',
      noEmploymentTypeLabel: 'Employment type missing',
    },
  },
  Documents: {
    NotesDocument: {
      title: 'Note',
      text: [
        "Bear in mind that these notes will stay with the employee during the rehabilition, even in an event of changing manager. It's a shared document.",
        'The employee has the rights to take part of the documentation in the Rehab Plan by anytime.',
      ],
      heading: { label: 'Title' },
      notes: { label: 'Notes' },
    },
    NotesFromPreviousDocuments: 'Notes from earlier meetings',
    PlanForReturnToWork: {
      title: 'Plan for returning to work',
      intro:
        'This form is based on the Statutory Check Ups of rehabilitation and will be followed up by Försäkringskassan. When needed, send the form to Försäkringskassan:',
      address: 'Försäkringskassans Inläsningscentral, 839 88 Östersund',
      workDescription: 'Work description',
      descriptionOfWork: 'Description of regular work tasks that can be done',
      descriptionOfWorkThatEmployeeIsUnableToPerform:
        'Description of regular work tasks that can not be done',
      action: {
        title: 'Actions',
        text: 'One or more considered actions and compensated work tasks is needed, regarding the employees situation, for a successful and quick rehabilitation.',
        workplaceVisit: {
          label: 'Work place visit',
          tooltip:
            'It is important to have knowledge about the work place to make the returning easier for the employee, based on the conditions. Ex. through compensated techniqual equipment, work-related help tools etc. ',
          measureWorkPlaceVisit: 'Motivation to a work place visit',
        },
        mentoring: {
          label: 'Tutorial',
          tooltip:
            'Is there any needs of certain support from managers or/and employees for the returning to work? Ex. avoid shift work for a certain time period if the illness was stress related. Compensate the work tasks. Avoid heavy lifts if the employee suffored from physiqually illness.',
          motivation: 'Motivation to tutorial',
        },
        tripsToWork: {
          label: 'Travels back and forth work',
          tooltip:
            'It is possible to apply for compensation for travels back and forth work, instead of sick pay from day 15. Ex. when the work capacity is intact, as in bone fracture and administrative work tasks. ',
          motivation: 'Motivation to travels back and forth work',
        },
        incrementalIncrease: {
          label:
            'Successive escalation of work time through part time sick leave',
          contentLabel: 'Successive escalation',
          tooltip:
            'It is common that the return to work needs to be done successive. Ex. 25% as in the action plan, then extended to 50%.',
          motivation:
            'Motivation to successive escalation of work time through part time sick leave',
        },
        adaption: {
          label: 'Compensation of the work place',
          motivation: 'Motivation to a compensated work place',
        },
        adaptedDuties: {
          label: 'Compensation of work tasks',
          motivation: 'Motivation to compensated work tasks',
        },
        equipment: {
          label: 'Help tools',
          motivation: 'Motivation to help tool',
        },
        otherWork: {
          label: 'Other work',
          motivation: 'Motivation to other work',
        },
        education: {
          label: 'Education or learning',
          motivation: 'Motivation to education or learning',
        },
        other: {
          label: 'Other action',
          motivation: 'Describe and motivate the chosen action',
        },
      },
      plan: {
        actionPlan: 'Action plan',
        text: 'Detailed action plan to get the employee back to work as soon as possible.',
        purpose: 'Action and purpose',
        startingFrom: 'From',
        planToDate: 'Until',
        followUpDate: 'The date for your next meeting',
        responsiblePerson: 'Responsible person',
      },
      evaluation: {
        title: 'Evaluation',
        texts: [
          'Fill in the evaluation when the date for the action plan expires, or at the date for your next meeting. ',
          'If the rehabilitation turns successful the Plan for returning to work will be considered finished. In other case a new Plan for returning to work will start, with compensated actions that enables the employee to get back to work as soon as possible.',
        ],
        summary: 'Evaluation summary',
        rehabilitationResults: 'The results of finished rehabilitation',
        dateForFinishedRehabilitation: 'Date for finished rehabilitation',
      },
      breadcrumbs: {
        workDescription: 'Work description',
        measures: 'Actions',
        planOfAction: 'Action plan',
        evaluation: 'Evaluation',
      },
    },
    WellnessCheck: {
      title: 'Wellness Check',
      text: 'Your employees can use the Wellness Check when they are in need of any form of rehabilitation in close connection to work. It can be useful in short-term and long-term absence, or when preventive actions is needed.  ',
      healthStatus: {
        title: 'Health Status',
        howAreYouTodayRange: 'How do you consider your health today?',
        howAreYouTodayReason: 'Description of health status',
        reasonForAbsence:
          'If sick leave has occurred – please describe the causes',
        workRelatedReasonsForAbsence:
          'Is there any causes connected to work that can have an affect on your health?',
      },
      isAbsenceWorkRelated: {
        title: 'Reported as occupational injury',
        tooltip: 'Occupational injury',
        tooltipContent:
          'An occupational injury is an injury or illness that has arisen due to an accident or otherwise caused by the work. It can also be an injury that has occurred on the way to or from work.',
        content: [
          '\u2022 The employer is obligated by law to report an occupational injury to the Swedish Social Insurance Agency. Read more here',
          '\u2022 If an occupational injury has been reported to the Swedish Social Insurance Agency, a safety representative must be notified. A serious work accident or a serious incident must also be reported to the Swedish Work Environment Authority.',
        ],
        moreInfo: 'Read more here',
      },
      isAbsenceApprovedAsWorkRelated: {
        title: 'Approved as a work injury',
        tooltipTitle: 'Approved work injury',
        tooltipContent:
          'The occupational injury is approved by the Swedish Social Insurance Agency.',
      },
      workRelatedCompensation:
        'If the employee has suffered an occupational injury or illness, he or she can receive compensation from Afa Insurance. <1>Read more here</1>.',
      healthImprovements: {
        title: 'Health improvments',
        employeeSuggestionsForImprovingHealth:
          'Suggestions from the employee to improve health and keep the work ability intact',
        managerSuggestionsForImprovingHealth:
          'Suggestions from the manager to improve health and keep the work ability intact',
        commonSuggestionsForImprovingHealth:
          'Plan to prevent illness and keep the work ability intact',
        dateForFollowup: 'Date for a follow-up meeting',
      },
      longTermAbsence: {
        title: 'In case of long-term absence',
        isAbsenceLongTerm: 'Is the absent period during more than 14 days?',
        dateOfPlannedReturn: 'Date of planned return',
        whosDoctorRegisteredAbsence: {
          previa: 'Falck',
          other: 'Other',
          label: 'Responsible doctor for the attestation of sickness',
          absenceStatus: 'Grade of sickness ',
          dateOfPlannedReturn: 'Expected return',
        },
        employeeReturnStatus: [
          'The employee will be able to return to ordinary work without any specific actions.',
          'The employee will not be able to return to ordinary work, regardless actions.',
          'The current health status of the employee eliminates a Plan for returning to work.',
          'The employee will be able to return to ordinary work without any specific actions. If needed, fill in the Plan for returning to work and leave it to Försäkringskassan.',
        ],
        comment: 'Comments',
        breadcrumbs: {
          healthStatus: 'Wellness Status',
          healthImprovements: 'Health improvments',
          longTermAbsence: 'In case of long-term absence',
        },
      },
    },
  },
  RolePicker: {
    label: 'Role:',
    roles: {
      HRAdmin: 'HR',
      Manager: 'Manager',
      StatisticsSupport: 'Statistics support',
      RehabCoordinator: 'Rehab coordinator',
      ActingManager: 'Acting manager',
      PersonalAdmin: 'Personnel admin',
      PersonnelAdmin: 'Personnel admin',
      AccountManager: 'Account manager',
    },
  },
  PeriodPicker: {
    showing: 'Showing',
    today: 'today',
    cancel: 'Cancel',
    show: 'Show',
    clear: 'Clear choice',
    choosePeriod: 'Choose period',
    currentMonth: 'current month ({{month}})',
    rangeFromToday: '{{months}} months from today',
    range: '{{start}}–{{end}}',
  },
  StatisticsCalculating: {
    calculating_previa: 'Calculating $t(HrAnalysis.comparisonTitle)',
  },
  LanguagePicker: {
    switch: 'På svenska',
  },
  StandardTable: {
    headers: {
      sort: {
        title_asc: 'Click to sort descending by {{ name }}',
        title_desc: 'Click to sort ascending by {{ name }}',
      },
    },
    showAll: 'Show all ({{count}})',
    exportTabName: 'Report',
  },
  AbsenceEvents: {
    title: {
      repeatShortTermAbsence: 'Repeated short-term absence',
    },
    headers: {
      department: 'Department',
    },
  },
  DataPoint: {
    loadingValue_previa: '<0></0> $t(HrAnalysis.comparisonTitle)',
  },
  CircleDataWidget: {
    comparison: '<0></0> {{entity}}',
  },
  Absence: {
    history: 'Absence history',
    lastWeek: 'Number of absent employees the last 7 days',
  },
  AbsenceList: {
    loading: {
      stats: 'Loading information about absent employees',
      initial: 'Loading the list of absent employees',
    },
    error: {
      stats:
        'Ett fel inträffade när informationen om frånvarande medarbetare skulle hämtas',
      initial:
        'Ett fel inträffade när listan med frånvarande medarbetare skulle hämtas',
      more: 'Ett fel inträffade när nästa del av listan skulle hämtas',
    },
    export: {
      filename: 'absent employees {{ date, iso-date }}',
      sheetName: 'Absent employees',
    },
    noFilters:
      'Select at least one type of absence to see the list of absences.',
  },
};
