export const sv = {
  common: {
    abort: 'Avbryt',
    by: 'av',
    and: 'och',
    datePicker: {
      weekdays: [
        'Söndag',
        'Måndag',
        'Tisdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lördag',
      ],
      weekdaysShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
      months: [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
      ],
    },
    day: 'Dag',
    dayN: 'Dag {{dayNumber}}',
    daySickLeave: 'Dag {{interval}}',
    sickLeaveInterval: {
      1: '1',
      7: '2-7',
      14: '8-14',
      30: '15-30',
      60: '31-60',
      90: '61-90',
      180: '91-180',
      365: '181-365',
      undefined: '366+',
    },
    days: 'dagar',
    month: 'månad',
    month_plural: 'månader',
    monthCount: '1 månad',
    monthCount_plural: '{{count}} månader',
    error: 'Felkod {{statusCode}}',
    remove: 'Ta bort',
    company: 'Företag',
    department: 'Avdelning',
    chart: {
      manager: {
        show: 'Visa alla chefer ({{count}})',
        hide: 'Dölj chefer',
      },
      clock: {
        show: 'Visa klockslag',
      },
      absenceType: {
        toggleVAB: 'Visa VAB',
      },
    },
    gender: {
      men: '<0>{{percentage}}</0> män',
      women: '<0>{{percentage}}</0> kvinnor',
    },
    ongoing: 'Pågående',
    list: {
      show: 'Visa lista',
      hide: 'Göm lista',
      true: 'Ja',
      false: 'Nej',
      headers: {
        name: 'Namn',
        causeOfAbsence: 'Frånvaro­orsak',
        startDate: 'Från och med',
        estimatedReturn: 'Beräknad åter­komst',
        department: 'Avdelning',
        absenceDegree: 'Frånvaro­grad',
        needForSupport: 'Behov av stöd',
        manager: 'Chef',
        isCurrent: 'Aktuell',
        date: 'Datum',
        service: 'Tjänst',
        category: 'Karaktär',
        numberOfOccasions: 'Antal till­fällen',
        numberOfNotifications: 'Antal händelser',
        absenceType: 'Typ av från­varo',
        workRelated: 'Arbets­relaterad',
      },
      showMore: 'Visa fler ({{remaining}})',
      readMore: 'Läs mer',
      minimize: 'Minimera',
      scrollToTop: 'Scrolla till toppen av listan',
      toggleShowAll: 'Visa utökade kolumner',
      loadingMore: 'Laddar fler rader',
      showSettings: 'Visa inställningar',
      showFilters: 'Visa filter',
      toggleWorkrelated: 'Visa arbetsrelaterad sjukfrånvaro',
      toggleSickLeave: 'Visa sjukfrånvaro (ej arbetsrelaterad)',
      toggleSickLeave_noWorkRelated: 'Visa sjukfrånvaro',
    },
    occurrences: 'tillfällen',
    navigation: {
      report: 'Rapport',
      analysis: 'Analys',
      rehabPlans: 'Rehabplaneraren',
      numberOfEmployees: '{{count}} medarbetare',
      employeesInCompany:
        'av {{num}} på {{company}} som använder Falck Sverige',
      today: 'Idag',
      today_0: 'Idag',
      today_plural: 'Idag <1><2>{{count}}</2></1>',
      concern: 'Koncern',
      generalStatisticsHeader: 'Sjuk och Frisk Statistik',
    },
    period: {
      current: 'Innevarande period',
      lastYear: 'Samma period föregående år',
    },
    yes: 'Ja',
    multiple: 'Flera',
    no: 'Nej',
    employeeWithActivePlan: 'Medarbetare sjukfrånvarande med aktiv rehabplan',
    employeesOnSickleave: 'Medarbetare sjukfrånvarande',
    relapse: 'Återinsjuknad',
    statusChange: 'Ny frånvarograd',
    previousEmployee: 'Tidigare anställd',
    notificationTypes: {
      WORK_RELATED_ABSENCE: 'Arbetsrelaterad frånvaro',
      LONG_TERM_ABSENCE: 'Långtidsfrånvaro',
      LONG_TERM_ABSENCE_15_DAYS: 'Långtidsfrånvaro längre än 14 dagar',
      LONG_TERM_ABSENCE_91_DAYS: 'Långtidsfrånvaro längre än 90 dagar',
      LONG_TERM_ABSENCE_181_DAYS: 'Långtidsfrånvaro längre än 180 dagar',
      REPEATED_SHORT_TERM_ABSENCES: 'Upprepad korttidsfrånvaro',
    },
    absenceTypeEnum: {
      SICK_LEAVE: 'Sjukanmälan',
      CARE_OF_CHILD: 'VAB',
      UNKNOWN: 'Okänd',
    },
    masked: 'Avidentifierad',
    token: {
      expired:
        'Din session har gått ut och kan inte längre förnyas. Vänligen logga ut och logga in igen.',
      expiresSoon:
        'Du har varit inaktiv under en längre tid. Vill du fortsätta att vara inloggad?',
      continue: 'Fortsätt',
      logOut: 'Logga ut',
    },
  },
  AtRisk: {
    longTerm: {
      title: 'Långtidsfrånvarande',
      person:
        '<0> {{ firstName }} </0> har varit sjukskriven längre än {{numberOfDays}} dagar',
      byDays: 'Långtidsfrånvaro i fler än {{numberOfDays}} dagar',
      reported:
        '{{date}} anmälde {{firstName }} frånvaro på grund av arbetsrelaterade skäl',
    },
    remove: {
      button: 'Ta bort riskmarkering',
      warning:
        'Är du helt säker på att du vill ta bort riskmarkerinen från <1>{{firstName}} {{lastName}}</1>?',
      warningText:
        'Ta bara bort den om du pratat med medarbetaren eller sedan tidigare vet att de inte ligger i riskzonen.',
    },
    workRelated: {
      title: 'Arbetsrelaterad frånvaro',
    },
    shortTerm: {
      title: 'Risk för långtidssjukskrivning',
      person:
        '<0> {{ firstName }} </0> har varit korttidsfrånvarande vid {{numberOfAbsences}} tillfällen de senaste sex månaderna. Det är en stark indikation på en kommande långtiddssjukskrivning.',
    },
  },
  CauseOfAbsence: {
    title: 'Frånvarotillfällen fördelat på orsak',
    title_days: 'Frånvarodagar fördelat på orsak',
  },
  ConditionsAndConditionCategories: {
    title: 'Frånvaro­tillfällen fördelat på kategori',
    title_days: 'Frånvaro­dagar fördelat på kategori',
    'Medicinska besvär': 'Medicinska besvär',
    'Besvär i rörelseapparaten': 'Besvär i rörelse­apparaten',
    'Psykosociala besvär': 'Psyko­sociala besvär',
    VAB: 'VAB',
    'Okänd kategori': 'Okänd kategori',
    Allergibesvär: 'Allergi­besvär',
    'Allmänna sjukdomsbesvär': 'Allmänna sjukdoms­besvär',
    'Besvär arm/hand': 'Besvär arm/​hand',
    'Besvär ben/fot': 'Besvär ben/​fot',
    'Besvär bröstkorg/revben': 'Besvär bröst­korg/​revben',
    'Besvär ländrygg/höft': 'Besvär länd­rygg/​höft',
    'Besvär nacke/bröstrygg/axlar': 'Besvär nacke/​bröst­rygg/​axlar',
    Förkylningsbesvär: 'Förkylnings­besvär',
    'Graviditetsrelaterade besvär': 'Graviditets­relaterade besvär',
    'Hjärt-/kärlbesvär': 'Hjärt-/​kärlbesvär',
    Hudbesvär: 'Hudbesvär',
    'Huvudvärk/yrsel': 'Huvudvärk/​yrsel',
    'Hörsel-/öronbesvär': 'Hörsel-/​öron­besvär',
    Influensabesvär: 'Influensa­besvär',
    'Lung-/luftvägsbesvär': 'Lung-/​luftvägs­besvär',
    'Mag-/tarmbesvär': 'Mag-/​tarm­besvär',
    Magsjukebesvär: 'Magsjuke­besvär',
    'Operation/konvalescens': 'Operation/​konvalescens',
    Sekretess: 'Sekretess',
    'Sjukvårdande behandling': 'Sjukvårdande behandling',
    'Stressrelaterade besvär': 'Stress­relaterade besvär',
    'Syn-/ögonbesvär': 'Syn-/​ögonbesvär',
    'Vill ej ange orsak': 'Vill ej ange orsak',
    'Övertaget ärende': 'Övertaget ärende',
    'Orsak ej förmedlad': 'Orsak ej förmedlad',
    'Okänd anledning': 'Okänd anledning',
  },
  ConditionCategoryIds: {
    'Okänd kategori': 'Okänd kategori',
    0: 'Sekretess',
    2: '',
    3: 'Besvär i rörelse­apparaten',
    4: 'Medicinska besvär',
    5: 'Psyko­sociala besvär',
    6: 'VAB',
    7: 'Orsak ej för­medlad',
  },
  ConditionIds: {
    'Okänd anledning': 'Okänd anledning',
    0: 'Sekretess',
    2: '',
    3: 'Allergi­besvär',
    4: 'Allmänna sjukdoms­besvär',
    5: 'Besvär arm/​hand',
    6: 'Besvär ben/​fot',
    7: 'Besvär bröst­korg/​revben',
    8: 'Besvär länd­rygg/​höft',
    9: 'Besvär nacke/​bröst­rygg/​axlar',
    10: 'Förkylnings­besvär',
    11: 'Graviditets­relaterade besvär',
    12: 'Hjärt-/​kärlbesvär',
    13: 'Hudbesvär',
    14: 'Huvudvärk/​yrsel',
    15: 'Hörsel-/​öron­besvär',
    16: 'Influensa­besvär',
    17: 'Lung-/​luftvägs­besvär',
    18: 'Mag-/​tarm­besvär',
    19: 'Magsjuke­besvär',
    20: 'Munhåla/tänder',
    21: 'Operation/​konvalescens',
    22: 'Psyko­sociala besvär',
    23: 'Sjukvårdande behandling',
    24: 'Stress­relaterade besvär',
    25: 'Syn-/​ögonbesvär',
    26: 'VAB',
    27: 'Vill ej ange orsak',
    28: 'Allergi/­över­känslighet',
    29: 'Behandling/­läkar­besök',
    30: 'Biv. Vaccin',
    31: 'Extre­miteter övre/­nedre',
    32: 'Feber',
    33: 'Graviditets­relaterade orsaker',
    34: 'Hjärta/­kärl',
    35: 'Hud',
    36: 'Huvud/­Neuro',
    37: 'Konfi­dentiellt',
    38: 'Luft­vägar/­lungor exkl. ÖLI',
    39: 'Lung­besvär',
    40: 'Mage/­tarm',
    41: 'Nacke/­thorax/­rygg',
    42: 'Operation',
    43: 'Psyk',
    44: 'Rörelse­apparat belastnings­orsak',
    45: 'Stress',
    46: 'Trötthet',
    47: 'Urin­vägar/­underlivs­besvär',
    48: 'Vård av anhörig',
    49: 'Ögon',
    50: 'Öron',
    51: 'Övre luft­vägs­infektion',
    52: 'Övrigt',
    53: 'öö Sär­skild Diagnos­grupp',
    54: 'Över­taget ärende',
  },
  ConditionsLegend: {
    sickness: 'Sjukdom',
    vab: 'VAB',
    upcoming: 'Kommande',
  },
  Counseling: {
    pane: {
      current: {
        heading: 'Nuläget',
        list: [
          'Hur mår du idag - både fysiskt och psykiskt?',
          'Finns det någon särskild orsak till ohälsan/frånvaron?',
          'Har du känt dig extra trött eller stressad? Hur hanterar du det?',
          'Hur hanterar du när det blir stressigt?',
        ],
      },
      plan: {
        heading: 'Åtgärdsplan',
        list: [
          'Vad kan du som chef vidta för åtgärder för att stärka måendet och minska ohälsan?',
          'Vad kan du som medarbetare göra för att förbättra din hälsa och öka din arbetsförmåga?',
          'När ska nästa uppföljande möte ske?',
        ],
      },
      private: {
        heading: 'Privat',
        list: [
          'Hur stämmer arbetstiderna med din sociala situation?',
          'Finns det samband mellan besvären och privatlivet?',
          'Hur ser din familjesituation ut? Socialt stöd och ekonomi?',
          'Hur ser dina levnadsvanor ut med fysisk aktivitet, kost, sömn, tobak, alkohol?',
          'Vad har du för fritidsintressen och hur mycket tid lägger du på dem?',
        ],
      },
      work: {
        heading: 'Arbetet',
        list: [
          'Hur trivs du på arbetet - både psykiskt och socialt?',
          'Finns det samband mellan besvären och arbetsplatsen?',
          'Finns de rätta förutsättningarna för arbetet fysiskt, psykiskt och socialt?',
          'Finns möjlighet för det stöd och uppmuntran på arbetsplatsen från arbetskamrater och chef vid behov?',
          'Kan du känna olust inför att gå till arbetet?',
        ],
      },
    },
    print: 'Skriv ut',
    title: 'Samtalsstöd',
    text: {
      intro:
        'Detta är en checklista att använda tillsammans som ett samtalsstöd för ett medarbetarsamtal om hälsa och arbetsmiljö. Syftet med samtalet är att utreda hur du som medarbetare mår, och hur du som chef kan stödja medarbetare och förebygga ohälsa.',
      second: 'Om det är lättare kan ni <1> skriva ut</1> frågorna',
    },
  },
  DepartmentPicker: {
    label: 'Avdelning:',
    filter: 'Sök avdelning',
    myDepartments: 'Mina avdelningar',
    allDepartments: 'Alla avdelningar',
    noSearchResult: 'Inga resultat för "{{searchValue}}"',
    viewSelector: {
      aggregated: {
        buttonText: 'Generell statistik',
        toolTipText:
          'Aggregerad statistik för avdelningar som du har behörighet till',
      },
    },
    lockInformation: {
      lacksAuthorization: 'Du saknar behörighet för denna avdelning.',
      lacksContract: 'Du saknar ett aktivt kontrakt för denna avdelning.',
      generalStatisticsInformation:
        ' Avdelningen exkluderas i Generell statistik.',
    },
    help: {
      header: 'Så funkar det',
      intro:
        'Klicka på avdelningens namn för att se underliggande alternativ. Om avdelningen saknar checkbox har du inte behörighet att se statistik för den avdelningen.',
      clickSteps: {
        one: 'Klicka <1>en</1> gång för att välja:',
        oneTitle: 'Avdelning inkl. underliggande',
        two: 'Klicka <1>två</1> gånger för att välja:',
        twoTitle: 'Avdelning exkl. underliggande',
        three: 'Klicka <1>tre</1> gånger för att avmarkera:',
        threeTitle: 'Ej vald avdelning',
      },
      parenthesesDescription:
        'Parentesen visar hur många underliggande avdelningar som är markerade',
      shortcuts: {
        header: 'Snabbkommandon',
        clickText: 'klick',
        ctrlPlusClick: 'för att markera/avmarkera enskild nod',
        altPlusClick:
          'för att avmarkera nod inkl. underliggande oavsett nuvarande tillstånd',
      },
    },
    footer: {
      helpActionText: 'Så funkar det',
      deselectAllActionText: 'Avmarkera alla',
      selectedDepartmentsActionText: '<0>{{count}}</0> markerad avdelning',
      selectedDepartmentsActionText_plural:
        '<0>{{count}}</0> markerade avdelningar',
      showSelection: 'Visa urval',
    },
    selectedDepartments: {
      header: 'Valda avdelningar',
      deselectAllActionText: 'Avmarkera alla',
      cancelActionText: 'Avbryt',
      saveActionText: 'Spara',
    },
    search: {
      input: {
        placeholder: 'Sök avdelning',
      },
    },
    columnMenu: {
      select: {
        header: 'Markera',
        actions: {
          selectIncludingUnderlying: 'Alla inkl. underliggande',
          selectExcludingUnderlying: 'Alla exkl. underliggande',
          selectOnlyUnderlying: 'Endast underliggande',
        },
      },
      deselect: {
        header: 'Avmarkera',
        actions: {
          deselectIncludingUnderlying: 'Alla inkl. underliggande',
          deselectExcludingUnderlying: 'Alla exkl. underliggande',
          deselectOnlyUnderlying: 'Endast underliggande',
        },
      },
    },
  },
  DepartmentPickerToggle: {
    label: 'Avdelningar',
    labelDepartment: 'Avdelning',
    chosenDepartments: 'Visar statistik för <1>{{count}}</1>',
  },
  ErrorPage: {
    server: {
      headline: 'Det uppstod ett fel.',
      first:
        'Något är fel hos oss, vi jobbar det snabbaste vi kan för att lösa problemet!',
      second:
        'Prova igen om en liten stund eller kontakta <1>sjukochfrisk@falcksverige.se</1> för mer information.',
    },
    forbidden: {
      headline: 'Behörighet saknas',
      first: 'Du är inte behörig att se den här sidan.',
    },
    notFound: {
      headline: 'Sidan du letar efter finns tyvärr inte.',
      first:
        'Gå tillbaka till <1>startsidan</1> eller kontakta <3>sjukochfrisk@falcksverige.se</3> för mer information.',
    },
    unavailable: {
      headline: 'Vi genomför underhåll.',
      first:
        'För närvarande är tjänsten inte tillgänglig, prova igen om en liten stund. <1 /> Regelbundna underhåll sker varje morgon mellan kl. <4>06.00–06.20</4>.',
      second:
        'Kontakta <1>sjukochfrisk@falcksverige.se</1> för mer information.',
    },
  },
  HrAnalysis: {
    comparisonTitle: 'för Falcks kunder',
    comparisonEntity: 'alla Sjuk och Frisk-kunder',
  },
  HrToday: {
    absentEmployees: 'medarbetare frånvarande',
    workRelated:
      'varav <2>{{ count }}</2> med upplevd arbetsrelaterad frånvaro',
    workersPerPeriod: 'Medarbetare per sjukfrånvaroperiod',
    sickLeave: '<0>{{count}}</0> medarbetare frånvarande',
    sickLeaveNonSelected: 'Frånvarande medarbetare',
    partialSupportForRehabplan:
      'Urvalet innehåller avdelningar vars företag inte använder Rehabplaneraren',
    returningToday: 'medarbetare tillbaka idag',
    absentEmployeesToday_0: 'Inga frånvarande idag',
    absentEmployeesToday: '<0>{{count}}</0> ny frånvarande idag',
    absentEmployeesToday_plural: '<0>{{count}}</0> nya frånvarande idag',
    returningTodayLoading: 'Hämtar medarbetare tillbaka i dag',
    absenceHistoryLoading: 'Hämtar frånvaro historik',
    employeesPerInterval: {
      loading: 'Hämtar medarbetare per sjukfrånvaroperiod',
    },
  },
  Notifications: {
    item: {
      title: 'Medarbetare',
      seeMore: 'Se detaljer och historik',
      name: '<0>{{personName}}</0> <1>({{notificationCount}} händelser)</1>',
      name_0: '<0>{{personName}}</0>',
    },
    longTerm: {
      label:
        'Medarbetare med långtidsfrånvaro längre än {{numberOfDays}} dagar',
    },
    repeated: {
      label: 'Medarbetare med upprepad korttidsfrånvaro',
      intro:
        'Upprepad korttidsfrånvaro är när en person varit frånvarande 3 eller fler gånger under 6 månader. Det är en indikation på en eventuell kommande längre sjukskrivning.',
      second:
        'Ibland har frånvaron naturliga orsaker men all upprepad korttidsfrånvaro bör följas upp för att kunna förhindra längre sjukskrivningar.',
    },
    title_plural: '<0>{{count}}</0> händelser att agera på',
    title: '<0>{{count}}</0> händelse att agera på',
    workRelated: {
      label: 'Medarbetare som anmält arbetsrelaterad frånvaro',
    },
    loading: 'Hämtar händelser',
  },
  Notification: {
    longTerm: 'Långtidsfrånvaro längre än {{numberOfDays}} dagar ({{count}})',
    workRelated: 'Arbetsrelaterad frånvaro ({{count}})',
    shortTerm: 'Upprepad korttidsfrånvaro ({{count}})',
  },
  Person: {
    absence: {
      title: 'Frånvarotillfällen per månad',
    },
    absent: 'Frånvarande',
    healthy: 'Frisk',
    employed: 'Anställd på {{- department}}',
    lastMonths: 'Senaste {{count}} månaderna',
    numberOfAbsences: '<0>{{count}}</0> tillfälle med totalt',
    numberOfAbsences_plural: '<0>{{count}}</0> tillfällen med totalt',
    numberOfAbsenceDays: '<0></0> frånvarodag',
    numberOfAbsenceDays_plural: '<0></0> frånvarodagar',
    absenceType: 'Frånvarotyp',
  },
  PersonAbsenceHistoryTable: {
    periodDates: '{{fromDate}} - {{toDate}}',
    history: 'Historik',
    table: {
      date: 'Datum',
      numberOfDays: 'Antal dagar',
      condition: 'Frånvaroorsak',
      percent: 'Frånvarograd',
      relapsePercent: 'Återinsjuknad/ny frånvarograd',
      workRelated: 'Arbetsrelaterad',
      conditionType: 'Typ av frånvaro',
    },
    showAll: 'Visa all historik',
  },
  PersonServiceUseHistoryTable: {
    activity: 'Aktivitet',
    showAll: 'Visa all aktivitet',
  },
  PlannedReturns: {
    heading: 'Planerade återkomster kommande 7 dagarna',
    date: '{{date}}',
    date_today: 'Idag, {{date}}',
  },
  Report: {
    report: 'Rapport',
    headline: 'Skapa rapport',
    export: 'Exportera',
    period: 'Period',
    selectAllColumns: 'Markera alla',
    summaryHeading: 'Ärendelista',
    summation: 'Summering',
    absencesForPeriod: 'Frånvarotillfällen för period',
    absenceDaysTotal: 'Frånvarodagar totalt (kalenderdagar)',
    absenceDaysForPeriod: 'Frånvarodagar för period',
    sicknessAbsence: 'Sjukfrånvaro',
    vabAbsence: 'VAB',
    abstractAbsences: 'Frånvarotillfällen ej återinsjuknad/ny frånvarograd',
    noAbsencesForPeriod:
      'Det finns inga frånvarotillfällen för den valda perioden',
    noSelectedColumns: 'Inga kolumner valda',
    showAll: 'Visa alla',
    exportFileNameHr: 'Generell statistik',
    columns: {
      employmentNumber: 'Anställningsnummer',
      name: 'Namn',
      department: 'Enhet',
      parentDepartment: 'Överordnad enhet',
      conditionName: 'Frånvaroorsak',
      conditionType: 'Typ av frånvaro',
      absencesForPeriod: 'Frånvarotillfällen för period',
      absencesExclRelapsesForPeriod:
        'Frånvarotillfällen (ej återinsjuknad/ny frånvarograd)',
      fromDate: 'Sjukanmälan gäller fr.o.m.',
      toDate: 'Sista frånvarodag',
      realReturnDate: 'Beräknad återkomst',
      callDatetime: 'Sjukanmälan inkom',
      callDatetimeWell: 'Friskanmälan inkom',
      isAbsent: 'Friskanmäld',
      absenceStatus: 'Frånvarograd',
      absenceDaysForPeriod: 'Frånvarodagar för period',
      totalAbsenceDaysForPeriod: 'Frånvarodagar totalt',
      workRelated: 'Arbetsrelaterad frånvaro',
    },
  },
  RehabPlanList: {
    header: '<0>{{count}}</0> Rehabärenden',
    header_ongoing: '<0>{{count}}</0> Pågående rehabärende',
    header_ongoing_plural: '<0>{{count}}</0> Pågående rehabärenden',
    header_concluded: '<0>{{count}}</0> Avslutat rehabärende',
    header_concluded_plural: '<0>{{count}}</0> Avslutade rehabärenden',
    header_plannedMeasures: '<0>{{count}}</0> Rehabärende med planerad åtgärd',
    header_plannedMeasures_plural:
      '<0>{{count}}</0> Rehabärenden med planerad åtgärd',
    loading: {
      stats: 'Laddar information om rehabärenden',
      initial: 'Laddar lista med rehabärenden',
    },
    error: {
      stats:
        'Ett fel inträffade när informationen om rehabärenden skulle hämtas',
      initial: 'Ett fel inträffade när listan med rehabärenden skulle hämtas',
      more: 'Ett fel inträffade när nästa del av listan skulle hämtas',
    },
    export: {
      filename: 'rehabärenden {{ date, iso-date }}',
      sheetName: 'Rehabärenden',
    },
    columns: {
      employee: 'Medarbetare',
      rehabStatus: 'Rehabstatus',
      plannedMeasures: 'Planerade åtgärder',
      department: 'Avdelning',
      latestNote: 'Senaste anteckning',
    },
    rehabStatus: {
      ongoing: 'Pågående sedan {{ date, iso-date }}',
      ongoing_datetime: 'Pågående sedan {{ date, iso-date-time }}',
      concluded: 'Avslutad vid {{ date, iso-date }}',
      concluded_datetime: 'Avslutad vid {{ date, iso-date-time }}',
    },
    filters: {
      ongoing: 'Pågående',
      concluded: 'Avslutade',
      plannedMeasures: 'Planerade åtgärder',
    },
    latestNote: {
      planForReturnToWork: '{{date, iso-date }}: Plan för återgång till arbete',
      planForReturnToWork_datetime:
        '{{date, iso-date-time }}: Plan för återgång till arbete',
      note: '{{date, iso-date }}: Mötesanteckning',
      note_datetime: '{{date, iso-date-time }}: Mötesanteckning',
      wellnessCheck: '{{date, iso-date }}: Välmåendekoll',
      wellnessCheck_datetime: '{{date, iso-date-time }}: Välmåendekoll',
      file: '{{date, iso-date }}: Filuppladding',
      file_datetime: '{{date, iso-date-time }}: Filuppladding',
    },
    plannedMeasures: {
      planForReturnToWork: '{{ date, iso-date }}: Nästa möte',
      planForReturnToWork_datetime: '{{ date, iso-date-time }}: Nästa möte',
      wellnessCheck: '{{ date, iso-date }}: Uppföljning',
      wellnessCheck_datetime: '{{ date, iso-date-time }}: Uppföljning',
    },
  },
  RehabPlanManager: {
    title: 'Rehabplaneraren',
    constants: {
      wellnessCheck: 'Välmåendekoll',
      planForReturnToWork: 'Plan för återgång till arbete',
      notes: 'Anteckning',
    },
    employment: 'Anställd på {{name}}',
    print: 'Skriv ut',
    personNumber: 'Personnummer',
    save: 'Spara',
    close: 'Stäng',
    todaysDate: 'Dagens datum',
    date: 'Datum',
    employer: 'Arbetsgivare',
    employee: 'Medarbetare',
    thirdParty: 'Tredjepart',
    introduction: [
      'Rehabplaneraren hjälper dig och din medarbetare att tillsammans göra en plan för hur din medarbetare ska må bra igen och komma tillbaka till arbetet på ett bra sätt.',
      'Påbörja en rehabplan till höger och fyll i välmåendekollen tillsammans. Vid behov fyller ni i plan för återgång till arbete och lämnar den till Försäkringskassan.',
      'Medarbetaren kan när som helst begära ut allt innehåll i sin rehabplan.',
    ],
    ongoing: 'Pågående',
    finishedPlans: 'Avslutade {{number}}',
    noPlans: 'Det finns inga pågående planer för denna medarbetare.',
    startPlan: 'Påbörja en ny rehabplan',
    startWellnessCheck: 'Börja med en välmåendekoll',
    startPlanToReturnToWork: 'Börja med en plan för återgång till arbete',
    startNote: 'Börja med en anteckning',
    startFile: 'Börja med att ladda upp dokument',
    noArchivedPlans:
      'Det finns inga avslutade rehabplaner för denna medarbetare.',
    noAccessNoActivePlans: [
      'Medarbetaren har inga aktiva Rehabplaner',
      'För att se innehållet i rehabplanen behöver du inneha rollen <em>HR</em>, <em>Rehabsamordnare</em> eller <em>Chef</em>.',
    ],
    fileUploadErrors: {
      tooLarge: 'Filen är för stor. Den största tillåtna storleken är 20 MB', // TODO: use value from config
      uploadError:
        'Något gick fel vid uppladdning av filen. Var god försök igen senare.',
      unknown: 'Ett oväntat fel inträffade. Var god försök igen senare.',
    },
  },
  RehabPlanManagerPlan: {
    title: 'Rehabplan',
    ongoing: 'påbörjad {{date}}',
    archived: 'avslutad {{date}}',
    archive: 'Avsluta plan',
    addWellnessCheck: 'Ny välmåendekoll',
    addplanForReturnToWork: 'Ny plan för återgång till arbete',
    addNote: 'Ny anteckning',
    addFile: 'Ladda upp dokument',
    resumePlan: 'Återuppta plan',
    removePlan: 'Ta bort plan',
    removeModal: {
      title: 'Är du säker på att du vill ta bort planen?',
      text: 'Planen och alla dokument som hör till planen tas bort permanent och går inte att återskapa.',
    },
    noAccessActivePlan: [
      'Medarbetaren har en aktiv Rehabplan',
      'För att se innehållet i rehabplanen behöver du inneha rollen <em>HR</em>, <em>Rehabsamordnare</em> eller <em>Chef</em>.',
    ],
  },
  RehabPlanManagerDocument: {
    updatedBy_unknown: 'Uppdaterad av Okänd användare {{timestamp}}',
    updatedBy: 'Uppdaterad av {{author}} {{timestamp}}',
    createdBy_unknown: 'Skapad av Okänd användare {{timestamp}}',
    createdBy: 'Skapad av {{author}} {{timestamp}}',
    modal: {
      title: 'Är du säker på att du vill ta bort dokumentet?',
      text: 'Dokumentet tas bort permanent och går inte att återskapa.',
    },
  },
  RiskTypes: {
    headline: 'Kontakta Falck för stöd & rådgivning',
    counselingStart: 'Starta samtalsstöd',
    checklist: [
      'Ha regelbundna avstämningar med din medarbetare. Säkerställ att den åtgärdsplan (i samtalsstödet) som finns fungerar, annars behöver den revideras så att den är aktuell.',
      'Din medarbetare är skyldig att medverka i planering och åtgärder, med mål att komma tillbaka så snart som möjligt.',
      'Boka in avstämningsmöte med sjukskrivande läkare om behov finns.',
      'Anpassa arbetet så mycket det går, så att medarbetaren kan arbeta. Ex. arbetsuppgifter, arbetstid, arbetshjälpmedel.',
      'Kan deltidssjukskrivning vara lämpligt för att korta sjukskrivningens längd?',
      'Om inte deltidssjukskrivning fungerar, kan Arbetsträning vara ett alternativ. Den är kravlös och anpassad, kan beviljas av Försäkringskassan om det anses var en effektiv rehabiliteringsåtgärd.',
      'Delta i avstämningsmöten, om Försäkringskassan bjuder in till det.',
      'Falck är gärna med och bidrar med sina expertkunskaper för att säkerställa en aktiv rehabiliteringsprocess.',
    ],
    intro: 'Det här kan du som chef göra',
    invite: 'Bjud in till samtal',
    workRelated: {
      first:
        'Som arbetsgivare har du ett ansvar för arbetsmiljön, så att medarbetarna kan utföra sina arbetsuppgifter utan att bli skadade eller sjuka.',
      second: {
        headline: 'Utgå från företagets Systematiska Arbetsmiljöarbete: (SAM)',
        list: {
          investigate: 'Undersök',
          riskAnalysis: 'Gör en riskbedömning',
          act: 'Åtgärda',
          followUp: 'Följ upp',
        },
        print:
          'Skriv ut underlaget och fyll i det tillsammans med din medarbetare.',
        pdf: 'Samtalsunderlag (PDF)',
      },
    },
    longTerm: {
      headline: 'Från dag 15',
      insurance: 'Din medarbetare får nu sjukpenning av Försäkringskassan.',
      rehab:
        'Enligt Försäkringskassans rehabiliteringskedja bedöms nu om medarbetaren kan utföra sitt vanliga arbete eller något tillfälligt arbete du som arbetsgivare kan erbjuda.',
      rehabPlaneraren: {
        texts: [
          'Om du inte skapat en Rehabplan för frånvaro innan dag 15, börja då med att fylla i en Välmåendekoll.',
          'Fortsätt sedan från dag 15, Plan för återgång i arbete.',
          'Tänk på att informera din medarbetare om rättigheten att ha med sig skyddsombud/facklig representant till era möten.',
          'Senast dag 30 ska plan för återgång i arbete vara ifylld, om din medarbetare antas vara sjuk i minst 60 dagar.',
        ],
        insurance: 'Läs försäkringskassans information',
      },
    },
    longerTerm: {
      headline: 'Från dag 91',
      expertice:
        'Falck är gärna med och bidrar med sina expertkunskaper för att säkerställa en aktiv rehabiliteringsprocess.',
      insurance: 'Läs försäkringskassans information',
    },
    longestTerm: {
      headline: 'Från dag 181-365',
      texts: [
        'Nu har din medarbetare bara rätt till sjukpenning i den omfattning som hen inte kan utföra något arbete på den vanliga arbetsmarknaden.',
        'Försäkringskassan utreder därför innan dag 180 om hen kan utföra annat arbete utanför din verksamhet. Om så är fallet kan rätten till sjukpenning upphöra.',
      ],
      exceptions: {
        headline: 'Undantag:',
        texts: [
          'Försäkringskassan bedömer att medarbetaren med stor sannolikhet kommer att kunna återgå till arbete i ordinarie omfattning, någonstans i din verksamhet före dag 366. Läkarintyget måste vara utförligt ifyllt med diagnos och prognos och en tydlig handlingsplan som ger stöd för detta.',
          'Om din medarbetare har en svår sjukdom, är det oskäligt att göra en bedömning utifrån vanliga arbetsmarknaden. Då gäller istället förmågan i förhållande till arbete hos dig som arbetsgivare.',
          'Din medarbetare ska fortsatt medverka i planering och åtgärder, med mål att komma tillbaka så snart som möjligt.',
        ],
      },
      responsibility:
        'Du förväntas som chef fortsätta med ditt aktiva rehabiliteringsarbete',
    },
    repeated: {
      schedule:
        'Prata direkt med medarbetaren för att bestämma en tid när ni kan ses. Undvik att mejla.',
      counseling:
        'Dela <1>samtalstödet</1> med medarbetaren i förväg så kan medarbetaren förbereda sig bättre.',
      talk: 'Samtala',
      purpose:
        'Berätta om syftet och varför ni har samtalet (t.ex. upprepad korttidssjukskrivning). Förtydliga att det är ditt ansvar som chef att förebygga ohälsa på arbetsplatsen. Förklara varför du är orolig för medarbetaren. Försök att skapa en öppen dialog som inte tvingar medarbetaren i försvar.',
      help: 'Ta hjälp av <1>samtalstödet</1>.',
      createPlan: 'Skapa rehabplan och uppföljning',
      endMeeting:
        'Vid behov skapa en rehabplan som säger vem som har ansvar för vad, och när uppföljningen ska ske. Avsluta mötet med att boka tid för en uppföljning och nästa steg.',
      reminder: 'Tänk på',
      indication:
        'Att en person har upprepad korttidsfrånvaro kan vara en indikation på kommande långtidssjuksskrivning - men det kan såklart finnas andra skäl för frånvaron.',
      askForHelp: 'Ta hjälp av HR om du känner dig osäker eller vill ha hjälp',
      offerSupport:
        'Erbjud personen att ta med en facklig representant eller annan stödperson',
    },
  },
  SearchEmployees: {
    placeholder: 'Sök medarbetare',
    noResult: 'Inget sökresultat för <1>"{{value}}"</1>',
  },
  SiteFooter: {
    about: 'Om statistikverktyget',
  },
  SiteHeader: {
    logout: 'Logga ut',
    home: 'Till kundsidan',
    userGuide: 'Användarguide',
  },
  StatisticsAnalysis: {
    series: [
      {
        label: 'Total sjukfrånvaro',
        tooltip: 'total sjukfrånvaro',
      },
      {
        label: 'VAB',
      },
      {
        label: 'Sjukfrånvaro föregående år',
        tooltip: 'sjukfrånvaro föregående år',
      },
      {
        label: 'Långtidsfrånvaro',
        tooltip: 'långtidsfrånvaro',
      },
      {
        label: 'Korttidsfrånvaro',
        tooltip: 'korttidsfrånvaro',
      },
    ],
    decimalsAreRounded: 'Decimaler är avrundade',
    lastMonths:
      'All statistik beräknas på {{periodText}} och jämför med {{entity}}',
    attendance: 'frisknärvaro',
    absenceFree: 'Medarbetare utan sjukfrånvaro',
    shortTerm: 'Korttidsfrånvaro',
    longTerm: 'Långtidsfrånvaro',
    totalAbsence: 'Total frånvaro',
    daysPerEmployee: 'Sjukfrånvarodagar per medarbetare',
    daysPerShortTerm: 'Sjukfrånvarodagar per korttidsfrånvaro',
    absencesPerEmployee: 'sjukfrånvarotillfällen per medarbetare',
    trend: 'Trend',
    absenceLastMonths: 'Frånvaro {{periodText}}',
    absenceOccasions: 'sjukfrånvarotillfällen med',
    absentDays: 'sjukfrånvarodagar',
    notificationsWhere: 'händelser varav',
    areCurrent: 'är aktuella',
    noNotifications: 'Inga händelser eller korttidsfrånvarotillfällen',
    noServiceUses: 'Inga aktiviteter',
    serviceUses: 'aktivitet',
    serviceUses_plural: 'aktiviteter',
    calculating: 'Beräknar statistik',
    analysis: 'Analys',
    periodRangeTitle_current: 'Visar <1>innevarande månad</1>',
    periodRangeTitle_current_plural:
      'Visar <1>{{periodStart, day-month-year}} – idag ($t(common.monthCount, {"count": {{months}}}))</1>',
    periodRangeTitle_notcurrent: 'Visar <1>{{periodStart, month-year}}</1>',
    periodRangeTitle_notcurrent_plural:
      'Visar <1>{{periodStart, month-year}} – {{periodEnd, month-year}} ($t(common.monthCount))</1>',
    periodRangeSubTitle:
      'All statistik beräknas på $t(common.monthCount, {"count": 1}) och jämförs med {{comparisonEntity}}',
    periodRangeSubTitle_plural:
      'All statistik beräknas på $t(common.monthCount) och jämförs med {{comparisonEntity}}',
    periodRangeSubTitle_current:
      'All statistik beräknas på innevarande månad och jämförs med {{comparisonEntity}}',
    periodRangeSubTitle_current_plural:
      'All statistik beräknas på innevarande månad och $t(common.monthCount, {"count": {{months}}}) bakåt och jämförs med {{comparisonEntity}}',
    DataAnalysis: {
      title: 'Nyckeltal',
      periodPickerShowing: 'Välj period',
      employeeCount: 'medarbetare',
      employeeCountHelpText:
        'Antalet medarbetare på vald enhet-/er utan hänsyn tagen till anställningsgrad för vald tidsperiod.',
      workingDaysCount: 'anställningsdagar',
      workingDaysCountHelpText:
        'Antalet anställningsdagar med hänsyn tagen till anställningsgrad för vald tidsperiod.',
      attendance: 'frisknärvaro',
      attendanceHelpText_company:
        'Antalet medarbetare som inte varit frånvarande för egen sjukdom av totala antalet medarbetare på vald enhet-/er under vald tidsperiod.\n\nJämförelse med hela företaget',
      attendanceHelpText_previa:
        'Antalet medarbetare som inte varit frånvarande för egen sjukdom av totala antalet medarbetare på vald enhet-/er under vald tidsperiod.\n\nJämförelse med alla Falcks kunder',
      shortTermAbsenceTitle: 'Korttidsfrånvaro',
      longTermAbsenceTitle: 'Långtidsfrånvaro',
      sickDaysPerEmployee: 'Sjukfrånvarodagar per medarbetare',
      sickOccasionsPerEmployee: 'Sjukfrånvarotillfällen per medarbetare',
      sickDaysPerShortTermAbsence: 'Sjukfrånvarodagar per korttidsfrånvaro',
      absenceSummaryTitle: '<0><0></0></0> total sjukfrånvaro',
      otherSummaryTitle_withWorkRelated:
        '<0><0></0></0> medarbetare med arbetsrelaterad frånvaro<br/><3><0></0></3> medarbetare med upprepad korttidsfrånvaro',
      otherSummaryTitle_withoutWorkRelated:
        '<0><0></0></0> medarbetare med upprepad korttidsfrånvaro',
    },
    AbsenceSummary: {
      title:
        '<0><0></0></0> frånvarotillfällen med<br/><3><0></0></3> frånvarodagar',
      sickLeaveDays: 'Sjukfrånvarodagar',
      sickLeaveCount: 'Sjukfrånvarotillfällen',
      careOfChildDays: 'VAB-dagar',
      careOfChildCount: 'VAB-tillfällen',
    },
    Trend: {
      title: 'Trend',
      helpText: `Frånvaro i procent
Andel sjukdagar av totalt möjliga för antalet anställda inom vald tidsperiod och avdelning, med hänsyn tagen till frånvarograd. Beräkningen är gjord på kalenderdagar.

Frånvarotillfällen
Samtliga frånvarotillfällen som pågått inom vald period.

Frånvarodagar
Samtliga frånvarodagar som infallit inom vald period, med hänsyn tagen till frånvarograd.`,
      AbsencePercentage: 'Frånvaro i procent',
      AbsenceCount: 'Frånvarotillfällen',
      AbsenceDays: 'Frånvarodagar',
      exportButtonTitle: 'Exportera till Excel',
      absencePercentage: {
        subTitle: 'Frånvaro',
        series: {
          allSickLeave: {
            label: 'Total sjukfrånvaro',
            tooltip: 'total sjukfrånvaro',
          },
          shortTermAbsence: {
            label: 'Korttidsfrånvaro',
            tooltip: 'korttidsfrånvaro',
          },
          longTermAbsence: {
            label: 'Långtidsfrånvaro',
            tooltip: 'långtidsfrånvaro',
          },
          vab: {
            label: 'VAB',
            tooltip: 'VAB',
          },
          totalSickLeaveLastYear: {
            label: 'Sjukfrånvaro föregående år',
            tooltip: 'sjukfrånvaro föregående år',
          },
        },
      },
      absenceCount: {
        subTitle: 'Frånvarotillfällen',
        series: {
          allSickLeave: {
            label: 'Sjukfrånvarotillfällen',
            tooltip: 'sjukfrånvarotillfällen',
          },
          shortTermAbsence: {
            label: 'Korttidsfrånvarotillfällen',
            tooltip: 'korttidsfrånvarotillfällen',
          },
          longTermAbsence: {
            label: 'Långtidsfrånvarotillfällen',
            tooltip: 'långtidsfrånvarotillfällen',
          },
          vab: {
            label: 'VAB-tillfällen',
            tooltip: 'VAB-tillfällen',
          },
          totalSickLeaveLastYear: {
            label: 'Sjukfrånvarotillfällen föregående år',
            tooltip: 'sjukfrånvarotillfällen föregående år',
          },
        },
      },
      absenceDays: {
        subTitle: 'Frånvarodagar',
        series: {
          allSickLeave: {
            label: 'Sjukfrånvarodagar',
            tooltip: 'sjukfrånvarodagar',
          },
          shortTermAbsence: {
            label: 'Korttidsfrånvarodagar',
            tooltip: 'korttidsfrånvarodagar',
          },
          longTermAbsence: {
            label: 'Långtidsfrånvarodagar',
            tooltip: 'långtidsfrånvarodagar',
          },
          vab: {
            label: 'VAB-dagar',
            tooltip: 'VAB-dagar',
          },
          totalSickLeaveLastYear: {
            label: 'Sjukfrånvarodagar föregående år',
            tooltip: 'sjukfrånvarodagar föregående år',
          },
        },
      },
      Excel: {
        worksheetName: 'Trend',
        title_percentage: 'Trend – Frånvaro',
        title_count: 'Trend – Frånvarotillfällen',
        title_days: 'Trend – Frånvarodagar',
        periodText:
          'Period: {{ startDate, iso-date }} – {{ endDate, iso-date }}',
        fileName_percentage:
          'Trend - Frånvaro {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        fileName_count:
          'Trend - Frånvarotillfällen {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        fileName_days:
          'Trend - Frånvarodagar {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        headers: {
          year: 'År',
          month: 'Månad',
          totalSickLeave: 'Total sjukfrånvaro',
          longTermAbsence: 'Långtidsfrånvaro',
          shortTermAbsence: 'Korttidsfrånvaro',
          vab: 'VAB',
          totalSickLeaveLastYear: 'Sjukfrånvaro föregående år',
        },
      },
    },
    CategoriesAndConditions: {
      title: 'Orsakskategorier och orsak',
      AbsenceCount: 'Frånvarotillfällen',
      AbsenceDays: 'Frånvarodagar',
      exportButtonTitle: 'Exportera till Excel',
      Excel: {
        worksheetName: 'Kategori och orsak',
        title_count: 'Orsakskategorier och orsak',
        title_days: 'Orsakskategorier och orsak',
        periodText:
          'Period: {{ startDate, iso-date }} – {{ endDate, iso-date }}',
        fileName_count:
          'Kategori och orsak - Frånvarotillfällen {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        fileName_days:
          'Kategori och orsak - Frånvarodagar {{ startDate, iso-date }} - {{ endDate, iso-date }}',
        headers: {
          conditionCategory: 'Kategori',
          condition: 'Orsak',
          count_count: 'Frånvarotillfällen fördelat på orsak',
          count_days: 'Frånvarodagar fördelat på orsak',
        },
      },
    },
    AbsenceEvents: {
      title: 'Upprepad korttidsfrånvaro',
      exportButtonTitle: 'Exportera till Excel',
    },
    ExportKeyValues: {
      action: 'Statistikexport',
      Excel: {
        title: 'Period {{periodStart, month-year}}',
        title_plural:
          'Period {{periodStart, month-year}} – {{periodEnd, month-year}} ($t(common.monthCount))',
        worksheetName: 'Statistik',
        fileName:
          'Statistikexport {{ startDate, iso-date }} – {{ endDate, iso-date }}',
        headers: {
          keyFigures: 'Nyckeltal',
          employeeCount: 'Medarbetare',
          totalEmploymentDays: 'Anställningsdagar',
          employeeAttendancePercentage: 'Frisknärvaro',
          totalSickLeave_withFilter: 'Total sjukfrånvaro',
          totalSickLeave_loading: 'Total sjukfrånvaro',
          totalSickLeave_company:
            'Total sjukfrånvaro (Jämförelse hela företaget)',
          totalSickLeave_previa:
            'Total sjukfrånvaro (Jämförelse Falcks kunder)',
          shortTermAbsences_withFilter: 'Korttidsfrånvaro',
          shortTermAbsences_loading: 'Korttidsfrånvaro',
          shortTermAbsences_company:
            'Korttidsfrånvaro (Jämförelse hela företaget)',
          shortTermAbsences_previa:
            'Korttidsfrånvaro (Jämförelse Falcks kunder)',
          longTermAbsences_withFilter: 'Långtidsfrånvaro',
          longTermAbsences_loading: 'Långtidsfrånvaro',
          longTermAbsences_company:
            'Långtidsfrånvaro (Jämförelse hela företaget)',
          longTermAbsences_previa:
            'Långtidsfrånvaro (Jämförelse Falcks kunder)',
          personWithWorkRelatedAbsenceCount:
            'Antal medarbetare med arbetsrelaterad sjukfrånvaro',
          personWithRepeatedShortTermAbsenceCount:
            'Antal medarbetare med upprepad korttidsfrånvaro',
          absentDaysPerEmployment: 'Sjukfrånvarodagar per medarbetare',
          absencesPerEmployee: 'Sjukfrånvarotillfällen per medarbetare',
          absentDaysPerAbsences: 'Sjukfrånvarodagar per korttidsfrånvaro',
          totalAbsences: 'Frånvarotillfällen',
          totalAbsentDays: 'Frånvarodagar',
          totalSicknessAbsentDays: 'Sjukfrånvarodagar',
          totalSicknessAbsences: 'Sjukfrånvarotillfällen',
          totalCareOfChildAbsentDays: 'VAB-dagar',
          totalCareOfChildAbsences: 'VAB-tillfällen',
        },
      },
    },
    AnalysisFilter: {
      Male: 'Man',
      Female: 'Kvinna',
      ActionLabel: '{{minAge}} – {{maxAge}} år',
      SubmitButton: 'OK',
      ResetButton: 'Återställ filter',
      GenderPicker: 'Kön',
      AgePicker: 'Ålder',
      EmploymentTypePicker: 'Anställningstyp',
      Heading: 'Välj filterinställningar',
      HoverText: 'Välj filterinställningar',
      MinAge: 'Välj minimum ålder',
      MaxAge: 'Välj maximum ålder',
      ChooseEmploymentType: 'Välj anställningstyp',
      CheckAllEmploymentTypes: 'Markera alla',
      UncheckAllEmploymentTypes: 'Avmarkera alla',
      InvertEmploymentTypesSelection: 'Invertera markering',
      noEmploymentTypeLabel: 'Anställningstyp saknas',
    },
  },
  Documents: {
    FileDocument: {
      title: 'Ladda upp dokument',
    },
    NotesDocument: {
      title: 'Anteckning',
      text: [
        'Tänk på att dina anteckningar följer med din medarbetare under hela rehabiliteringstiden oavsett om ett chefsbyte sker och att det är ett dokument ni har tillsammans.',
        'Medarbetaren kan när som helst begära ut sina dokument.',
      ],
      heading: { label: 'Rubrik' },
      notes: { label: 'Anteckning' },
    },
    NotesFromPreviousDocuments: 'Anteckningar från tidigare möten',
    PlanForReturnToWork: {
      title: 'Plan för återgång till arbete',
      intro:
        'Detta fomulär är baserad på de lagstadgade kraven kring rehabilitering och som följs upp av Försäkringskassan. Vid behov skicka blanketten till Försäkringskassan:',
      address: 'Försäkringskassans Inläsningscentral, 839 88 Östersund',
      workDescription: 'Arbetsbeskrivning',
      descriptionOfWork:
        'Beskrivning av ordinarie arbetsuppgifter som kan utföras',
      descriptionOfWorkThatEmployeeIsUnableToPerform:
        'Beskrivning av ordinarie arbetsuppgifter som inte kan utföras',
      action: {
        title: 'Åtgärder',
        text: 'För en bra och snabb rehabilitering kan det behövas en eller flera av föreslagna åtgärder och/eller andra åtgärder som anpassats för medarbetarens behov.',
        workplaceVisit: {
          label: 'Arbetsplatsbesök',
          tooltip:
            'Känna till arbetsplatsens utformning för att underlätta återgången för medarbetaren utifrån dess förutsättningar. Ex. genom anpassning, teknisk utrustning, arbetshjälpmedel m.m',
          measureWorkPlaceVisit: 'Motivering till arbetsplatsbesök',
        },
        mentoring: {
          label: 'Handledning',
          tooltip:
            'Finns behov av särskilt stöd till chef och/eller medarbetare vid återgången till arbete. Ex. stressrelaterad ohälsa, kanske undvika skiftgång en begränsad tid, avgränsa arbetsuppgifter. Fysisk ohälsa, kanske säkerställa möjlighet att undvika frekventa tunga lyft m.m',
          motivation: 'Motivering till handledning',
        },
        tripsToWork: {
          label: 'Resor till och från arbete',
          tooltip:
            'Går att ansöka om ersättning för resor till och från arbetet istället för sjukpenning från dag 15. Ex. Benfraktur och administrativa arbetsuppgifter, där arbetsförmågan för att utföra arbetet är intakt.',
          motivation: 'Motivering till resor till och från arbete',
        },
        incrementalIncrease: {
          label:
            'Successiv upptrappning av arbetstiden via deltidssjukskrivning',
          contentLabel: 'Successiv upptrappning',
          tooltip:
            'Vanligt att återgång till arbetet behöver ske succesivt. Ex. 25% enligt handlingsplan och sedan utökning till ex 50%',
          motivation:
            'Motivering till successiv upptrappning av arbetstiden via deltidssjukskrivning',
        },
        adaption: {
          label: 'Anpassning av arbetsplatsen',
          motivation: 'Motivering till anpassning av arbetsplatsen',
        },
        adaptedDuties: {
          label: 'Anpassning av arbetsuppgifter',
          motivation: 'Motivering till anpassning av arbetsuppgifter',
        },
        equipment: {
          label: 'Arbetshjälpmedel',
          motivation: 'Motivering till arbetshjälpmedel',
        },
        otherWork: {
          label: 'Annat arbete',
          motivation: 'Motivering till annat arbete',
        },
        education: {
          label: 'Utbildning eller upplärning',
          motivation: 'Motivering till utbildning eller upplärning',
        },
        other: {
          label: 'Annan åtgärd',
          motivation: 'Beskriv och motivera åtgärden',
        },
      },
      plan: {
        actionPlan: 'Handlingsplan',
        text: 'Utförlig plan av åtgärder för att få medarbetaren i arbete så snart som möjligt.',
        purpose: 'Åtgärd och syfte',
        startingFrom: 'Gäller from och med',
        planToDate: 'Till och med',
        followUpDate: 'Datum för ert nästa möte',
        responsiblePerson: 'Ansvarig person',
      },
      evaluation: {
        title: 'Utvärdering',
        texts: [
          'Fyll i utvärderingen efter att handlingsplanens datum gått ut eller vid datumet för ert nästa möte.',
          'Är rehabiliteringen lyckad avslutas Planen för återgång till arbetet, i annat fall påbörjas en ny Plan för återgång till arbetet med anpassade åtgärder för att medarbetaren ska komma tillbaka till arbete så snart som möjligt.',
        ],
        summary: 'Utvärdering, sammanfattning',
        dateForFinishedRehabilitation: 'Datum för avslutad rehabilitering ',
        rehabilitationResults: 'Resultat av avslutad rehabilitering',
      },
      breadcrumbs: {
        workDescription: 'Arbetsbeskrivning',
        measures: 'Åtgärder',
        planOfAction: 'Handlingsplan',
        evaluation: 'Utvärdering',
      },
    },
    WellnessCheck: {
      title: 'Välmåendekoll',
      text: 'Välmåendekoll kan användas när din medarbetare har behov av någon form av rehabilitering i samband med arbetet. Det kan vara både vid korttids- och långtidsfrånvaro eller om behov av förebyggande åtgärder finns.',
      healthStatus: {
        title: 'Välmåendestatus',
        howAreYouTodayRange: 'Hur skattar du din hälsa idag?',
        howAreYouTodayReason: 'Beskrivning av hälsoläge',
        reasonForAbsence:
          'Om det förekommit sjukfrånvaro - beskriv vad det har berott på',
        workRelatedReasonsForAbsence:
          'Finns det orsaker i arbetet som kan påverka ditt hälsoläge?',
      },
      isAbsenceWorkRelated: {
        title: 'Anmäld som arbetsskada',
        tooltip: 'Arbetsskada',
        tooltipContent:
          'En arbetsskada är en skada eller sjukdom som har uppkommit på grund av olycksfall eller på annat sätt orsakats av arbetet. Det kan även vara en skada som har inträffat på väg till eller från arbetet.',
        content: [
          '\u2022 Arbetsgivaren är enligt lag skyldig att anmäla arbetsskada till Försäkringskassan.',
          '\u2022 Om arbetsskada är anmäld till Försäkringskassan ska skyddsombud meddelas. En allvarlig arbetsolycka eller ett allvarligt tillbud ska också anmälas till Arbetsmiljöverket.',
        ],
        moreInfo: 'Läs mer här',
      },
      isAbsenceApprovedAsWorkRelated: {
        title: 'Godkänd som arbetsskada',
        tooltipTitle: 'Godkänd arbetsskada',
        tooltipContent: 'Arbetsskadan är godkänd av Försäkringskassan.',
      },
      workRelatedCompensation:
        'Om medarbetaren har drabbats av en arbetsskada eller sjukdom kan hen få ersättning från Afa Arbetsskadeförsäkring. <1>Läs mer här</1>',
      healthImprovements: {
        title: 'Hälsoförbättringar',
        employeeSuggestionsForImprovingHealth:
          'Förslag från medarbetaren för att öka hälsan och bibehålla arbetsförmågan',
        managerSuggestionsForImprovingHealth:
          'Förslag från chefen för att öka hälsan och bibehålla arbetsförmågan',
        commonSuggestionsForImprovingHealth:
          'Gemensam plan för att förhindra ohälsa och bibehålla arbetsförmågan',
        dateForFollowup: 'Datum för uppföljning',
      },
      longTermAbsence: {
        title: 'Vid långtidsfrånvaro',
        isAbsenceLongTerm: 'Är frånvaron längre än 14 dagar?',
        dateOfPlannedReturn: 'Datum för planerad återkomst',
        whosDoctorRegisteredAbsence: {
          previa: 'Falck',
          other: 'Annan',
          label: 'Läkare som har sjukskrivit',
          absenceStatus: 'Sjukskrivningsgrad',
          dateOfPlannedReturn: 'Beräknad återkomst',
        },
        employeeReturnStatus: [
          'Medarbetaren kommer att kunna återgå helt i ordinarie arbetet utan särskilda åtgärder.',
          'Medarbetaren kommer inte att kunna återgå i arbete, oavsett åtgärder.',
          'Medarbetarens hälsotillstånd gör att en plan för återgång för tillfället inte kan tas fram.',
          'Medarbetaren kommer att kunna återgå helt i ordinarie arbetet med särskilda åtgärder. Fyll i Plan för återgång till arbete och lämna till Försäkringskassan vid behov',
        ],
        comment: 'Kommentar',
        breadcrumbs: {
          healthStatus: 'Måendestatus',
          healthImprovements: 'Hälsoförbättringar',
          longTermAbsence: 'Vid långtidsfrånvaro',
        },
      },
    },
  },
  RolePicker: {
    label: 'Roll:',
    roles: {
      HRAdmin: 'HR',
      Manager: 'Chef',
      StatisticsSupport: 'Statistikstöd',
      RehabCoordinator: 'Rehabsamordnare',
      ActingManager: 'Tf chef',
      PersonalAdmin: 'Personaladministratör',
      PersonnelAdmin: 'Personaladministratör',
      AccountManager: 'Kundansvarig',
    },
  },
  PeriodPicker: {
    showing: 'Visar',
    today: 'idag',
    cancel: 'Avbryt',
    show: 'Visa',
    clear: 'Rensa val',
    choosePeriod: 'Välj period',
    currentMonth: 'innevarande månad ({{month}})',
    rangeFromToday: '{{months}} månader från idag',
    range: '{{start}}–{{end}}',
  },
  StatisticsCalculating: {
    calculating_previa: 'Räknar $t(HrAnalysis.comparisonTitle)',
  },
  LanguagePicker: {
    switch: 'In English',
  },
  StandardTable: {
    headers: {
      sort: {
        title_asc: 'Klicka för att sortera fallande på {{ name }}',
        title_desc: 'Klicka för att sortera stigande på {{ name }}',
      },
    },
    showAll: 'Visa alla ({{count}})',
    exportTabName: 'Rapport',
  },
  AbsenceEvents: {
    title: {
      repeatShortTermAbsence: 'Upprepad korttidsfrånvaro',
    },
    headers: {
      department: 'Avdelning',
    },
  },
  DataPoint: {
    loadingValue_previa: '<0></0> $t(HrAnalysis.comparisonTitle)',
  },
  CircleDataWidget: {
    comparison: '<0></0> {{entity}}',
  },
  Absence: {
    history: 'Frånvarohistorik',
    lastWeek: 'Antal medarbetare frånvarande senaste 7 dagarna',
  },
  AbsenceList: {
    loading: {
      stats: 'Laddar information om frånvarande medarbetare',
      initial: 'Laddar lista med frånvarande medarbetare',
    },
    error: {
      stats:
        'Ett fel inträffade när informationen om frånvarande medarbetare skulle hämtas',
      initial:
        'Ett fel inträffade när listan med frånvarande medarbetare skulle hämtas',
      more: 'Ett fel inträffade när nästa del av listan skulle hämtas',
    },
    export: {
      filename: 'frånvarande medarbetare {{ date, iso-date }}',
      sheetName: 'Frånvarande medarbetare',
    },
    noFilters:
      'Välj minst en typ av ärenden från filter-listan för att se ärenden.',
  },
};
